@tailwind base;

@tailwind components;

@tailwind utilities;

:root {
  --cl-gry: #777;
}

html {
  scroll-behavior: smooth;
}

/**-- Defaults --**/
* {
  @apply font-standard;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.highlight {
  color: #de3530;
}

body {
  scroll-behavior: smooth;
  background: #fafafa;

  &.modal-open {
    overflow: hidden;
  }
}

button:focus,
.modalcalss:focus {
  outline: 0;
  box-shadow: none;
}

.modalcalss {
  @media all and (max-width: 980px) {
    overflow-y: scroll;
  }
}

h1 {
  @apply text-2xl font-bold;

  @screen md {
    @apply text-2xl;
  }
}

h2 {
  @apply text-xl font-bold;

  @screen md {
    @apply text-xl;
  }
}

h3 {
  @apply text-xl font-normal;

  @screen md {
    @apply text-xl;
  }
}

p {
  @apply font-normal;
}

strong {
  @apply font-semibold;
}

.opacity-80 {
  opacity: 0.8;
}

button,
input[type="submit"],
a {
  @apply transition-colors duration-500;
}

// Need to change the input
input[type="text"],
select {
  transition: box-shadow 0.3s;
  background: #f2f2f2 !important;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.inputTextNumber {

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.howdoyouhearaboutus-margin {
  border-right-width: 1.25rem;
  border-right-color: #f2f2f2;
  padding-right: 0 !important;
}

.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded="true"] {
  background: #de3530;
}

.plyr--video .plyr__control.plyr__control--overlaid.plyr__tab-focus,
.plyr--video .plyr__control.plyr__control--overlaid:hover,
.plyr--video .plyr__control.plyr__control--overlaid[aria-expanded="true"],
.plyr button.plyr__control.plyr__control--overlaid {
  background: url(../images/icons/play-icon.svg) center no-repeat;
  opacity: 1;

  svg {
    visibility: hidden;
  }
}

.dd-form-field.dd-form-field--transparent {

  input,
  select,
  textarea {
    @apply py-3 px-5 w-full;
    background: transparent;
  }
}

.dd-form-field.dd-form-field--round {
  @apply rounded-full;
}

.dd-form-field.dd-form-field--bordered {
  @apply border-4;

  input,
  select,
  textarea {
    @apply w-full;
  }
}

/**-- Buttons --**/
.dd-button-cta {
  @apply py-3 font-semibold cursor-pointer;
  border-radius: 10px;
}

.dd-button-cta--primary {
  @apply text-red-light border-red-light px-12;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 42px;
  // padding-top: 7px;
  font-size: 16px;
  font-weight: 700;
  border-width: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 0;

  &:hover {
    @apply bg-red-light text-white;
  }
}

.dd-button-cta--primary-alt-none {
  border-radius: 10px;
  background: #de3530;
  min-width: 138px;
  text-transform: capitalize;
  font-weight: 500;
  padding: 0.75rem;
  text-align: center;
  display: inline-block;
  border: 1px solid transparent;
  color: #fff;
}

.dd-button-cta--secondary {
  @apply text-white px-12;

  &:hover {
    @apply text-white;
  }
}

.dd-button-cta--white {
  @apply bg-white text-gray-800 px-8;

  &:hover {
    @apply text-white;
  }
}

/**-- Components --**/
.dd-partial-underline {
  @apply inline-block relative;

  &:after {
    content: "";
    @apply absolute left-1/2 bottom-0 border-2 -mb-2;
  }
}

.dd-partial-underline--16:after {
  @apply w-16 -ml-8;
}

.dd-partial-underline--red-medium:after {
  @apply border-red-medium;
}

.dd-partial-underline--lime:after {
  //@apply border-lime;
}

.summary-hide {
  display: none;
}

.dd-arrow-down {
  @apply w-0 h-0 border;
  border-width: 0.5rem 0.5rem 0 0.5rem;
}

.dd-arrow-down--red {
  border-color: #b72927 transparent transparent transparent;
}

.dd-toast {
  transition: transform 0.5s;
  @apply fixed top-0 left-1/2 w-32 -ml-16 px-4 py-2;
  transform: translateY(-10rem);
}

.dd-toast--success {
  @apply text-white;
}

.dd-toast--active {
  transform: translateY(-10rem);
}

.dd-social-links .dd-social-links__icon {
  @apply w-16 h-16;
}

.dd-footer .dd-social-links .dd-social-links__icon {
  @apply w-8 h-8;
}

.dd-nav {
  .dd-nav__tab--selected a {
    @apply font-bold;
  }

  @media all and (max-width: 991px) {
    display: flex;
  }
}

.dd-truncate-text {
  --lh: 1.4rem;
  line-height: var(--lh);
  --max-lines: 3;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
}

.dd-nav--lime {
  @apply bg-tan;

  .dd-nav__tab {
    @apply relative;
  }

  .dd-nav__tab--selected {
    a {
      @apply text-red-medium;
    }

    &:after {
      content: "";
      @apply absolute w-1 h-6 bg-red-medium top-0 left-1/2 -ml-3;
    }
  }
}

.dd-nav--white {
  @apply bg-tan;
}

/**-- Animations --**/
.dd-pull-up {
  transition: transform 2s;

  &.sps--abv {
    transform: translateY(3rem);
  }

  &.sps--blw {
    transform: translateY(-3rem);
  }
}

.dd-pulse {
  animation: dd-pulse 1s linear 0s infinite;
}

@keyframes dd-pulse {
  from {
    transform: scale(0);
    filter: blur(0);
  }

  to {
    transform: scale(1.3);
    filter: blur(0.1rem);
  }
}

/**-- Datepicker --**/
.react-datepicker-wrapper,
.react-datepicker__input-container {
  @apply block;
}

.react-datepicker-wrapper .react-datepicker__input-container input {
  @apply w-full;
  @apply font-secondary;
  font-weight: 600;
  background: transparent !important;
}

.carousel__slider-tray--horizontal>div {
  padding: 0 15px 30px !important;
  height: auto;

  &>div {
    position: relative;
  }

  @media all and (max-width: 767px) {
    padding-bottom: 16px !important;
  }

  .cuisine & {
    padding: 0 16.5px 30px !important;
  }
}

.cuisine {
  .carousel__slider-tray--horizontal>div {
    padding-bottom: 0 !important;

    .cuisine-box {
      border-radius: 30px;
      overflow: hidden;
      border: 1px solid #ececec;
      position: relative;
    }

    a {
      &>strong {
        position: absolute;
        bottom: 9px;
        left: 15px;
        right: 15px;
        color: #d93d2f;
        font-weight: 700;
        background: #fff;
        border-radius: 30px;
        line-height: 28px;
        font-size: 14px;
        text-transform: capitalize;
        font-family: Montserrat, sans-serif;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 10px;
      }
    }
  }
}

/**-- Dropdown Select --**/
.dd-dropdown-select .react-dropdown-select {
  @apply rounded-full border border-gray-500 py-2;
}

/**-- Modal --**/
.dd-modal {
  @apply absolute;
}

.dd-modal--cc {
  @apply bg-white px-6 py-12 text-center rounded-2xl w-88 left-1/2;
  margin-left: -11rem;
  top: 25%;
}

.dd-modal--md {
  @apply bg-white px-6 py-12 text-center rounded-2xl w-132 left-1/2;
  margin-left: -17.5rem;
  top: 33.33%;
}

@media all and (max-width: 767px) {
  .dd-modal--md {
    width: 100%;
    left: 0;
    margin-left: 0;
    top: 50%;
    margin-top: -50%;
  }
}

.dd-modal-overlay {
  @apply fixed top-0 right-0 left-0 bottom-0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 99;
}

.dd-modal-overlay-dark {
  @apply fixed top-0 right-0 left-0 bottom-0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  overflow-y: hidden;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

.background-color-green {
  background-color: #bebf55;
}

.background-color-red {
  background-color: #b82b27;
}

.dd-underline:after {
  background-color: #b82b27;
}

.bg-black-dark.py-8 {
  padding-top: 26px;
  padding-bottom: 23px;
}

// .bg-black-dark.py-8 p {
//   color: var(--cl-gry);
// }

.link-wrapper {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 30px;
}

.link-wrapper .link-wrap {
  margin-bottom: 30px;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
}

.banner-data {
  padding-left: 0;

  @media screen and (max-width: 991px) {
    width: 100%;
  }

  @media all and (max-width: 767px) {
    .text-lime-shade {
      font-size: 37px;
      line-height: 45px;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.text-lime-shade {
  font-size: 47px;
  line-height: 52px;
  margin-bottom: 30px;
  font-weight: 700;
}

.bg-black {
  --bg-opacity: 1;
  background-color: #272727;
  background-color: rgba(39, 39, 39, var(--bg-opacity));
}

.text-white {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.img-wrapper>div {
  width: calc(50% - 13px);

  img {
    object-fit: cover;
  }
}

.img-wrapper>div:nth-child(4),
.img-wrapper>div:nth-child(3) {
  margin-top: 25px;
}

.dd-button-cta--primary-alt {
  border-radius: 10px;
  background: #de3530;
  min-width: 138px;
  text-transform: capitalize;
  font-weight: 700;
  padding: 0.75rem;
  text-align: center;
  display: inline-block;
  border: 1px solid transparent;
  color: #fff;

  &:hover {
    @apply bg-red-medium;
  }
}

.dd-button-cta--primary-alt.small {
  padding: 7px;
  max-height: 40px;
  font-size: 16px;
  font-weight: 700;
}

/* .dd-button-cta--primary-alt:hover {
    border-color: #de3530;
} */

.dd-button-cta--primary-alt.wide {
  width: 100%;
}

.location-banner {
  background: #fff;
  border-radius: 30px;
  display: flex;
  width: 100%;
  max-width: 1170px;
  margin: 40px auto 70px;
  align-items: center;
  position: relative;
  min-height: 210px;
  @media (max-width: 767px) {
    margin: 40px auto 12px;
  }
}

.location-banner .banner-data h2 {
  color: #222;
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 30px;

  @media all and (min-width: 768px) and (max-width: 991px) {
    font-size: 20px;
    line-height: 28px;
  }
}

.location-banner .banner-data {
  width: calc(100% - 428px);
  padding: 0 50px;
  order: initial;
}

.location-banner .cheif-banner-image {
  position: absolute;
  right: 0;
  top: -20px;
}

.form-wrap {
  display: flex;
  align-items: center;
}

.form-wrap label {
  width: 100%;
  display: block;
  font-size: 14px;
  color: #222;
  line-height: 1;
  margin-bottom: 10px;
  text-align: left;
  @apply font-secondary;
  text-transform: capitalize;
}

.form-wrap .text-gray-700 {
  color: var(--cl-gry);
  font-size: 14px;
}

.form-wrap .text-gray-700:hover {
  text-decoration: none;
}

.form-wrap .forgot-pass {
  text-align: right;
  line-height: 1;
  margin-top: -10px;
  @apply font-secondary;
}

.form-wrap .forgot-pass a {
  @apply font-secondary;
}

.form-wrap .search-form {
  background: #f2f2f2;
  border-radius: 12px;
  margin-right: 20px;
  max-width: 310px;
  width: 100%;
  @apply font-secondary;

  &.with-location {
    padding-left: 35px;

    &>.subscribe-XP.location {
      left: 3px;
      top: -102px;
      height: auto;

      @media all and (max-width: 991px) {
        left: 2px;
        top: calc(100% + 12px);
        height: auto;
      }
    }

    .icon {
      width: 24px;
      height: 24px;
      cursor: initial;

      &.withBounce {
        cursor: pointer;
      }

      img {
        position: relative;
        left: 0;
        top: 0;
        margin: 0;
      }
    }

    input {
      height: 56px;
    }
  }
}

.form-wrap .search-form.wide {
  min-width: 250px;
}

.form-wrap .search-form>div {
  padding-top: 13px;
  padding-bottom: 13px;
  height: 56px;
}

.form-wrap .search-form img {
  position: absolute;
  left: 14px;
  top: 15px;
  margin: 0;
}

textarea,
input {
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #777;
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #777;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #777;
  }
}

.form-wrap .search-form input {
  width: 100%;
  display: block;
  font-size: 14px;
  font-weight: 400;
  height: auto;
  padding: 5px 12px;
  @apply font-secondary;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #777;
    opacity: 1;
    /* Firefox */
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #777;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #777;
  }
}

@media all and (max-width: 1124px) {
  .head-with-img .h-64 {
    top: 0;
    margin: auto;
    height: 12rem;
  }
}

.container.pr-0 {
  padding-right: 0;
}

@media all and (max-width: 767px) {
  .head-with-img {
    padding-top: 0;
    padding-bottom: 20px;
  }

  .head-with-img .h-64 {
    top: auto;
    height: 5rem;
    bottom: -1rem;
  }

  .head-with-img .text-5xl {
    font-size: 2rem;
  }

  .create-kitchen-form {
    width: 100%;
  }

  .create-kitchen-form .dd-form-field.dd-form-field--round {
    grid-column: span 2 / span 2;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container.fluid {
  max-width: 100%;

  @media all and (min-width: 1200px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  &.wide {
    padding-left: 0;
    padding-right: 0;
  }
}

.dashboard-heading {
  margin-top: -83px;
  text-transform: uppercase;

  @media all and (max-width: 991px) {
    margin-top: 0;

    .page-heading {
      margin-bottom: 30px;
    }
  }
}

.dashboard-heading .user-heading {
  text-transform: uppercase;
  @apply font-secondary;
  font-weight: 500;
  color: #777;
  font-size: 14px;
}

.dashboard-heading .page-heading {
  line-height: 38px;
  text-transform: none;
}

.carousel ul {
  display: flex;
  margin-bottom: 10px;
}

.carousel li {
  padding-left: 15px;
  padding-right: 15px;
  height: auto;
  padding-bottom: 0 !important;
}

.carousel .carousel__slider-tray-wrapper {
  margin-left: -15px;
  margin-right: -15px;
}

.chef-of-month .carousel li {
  padding: 0;
}

.carousel li>div {
  position: relative;
}

.carousel li>div>div {
  border-radius: 22px;
  box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, 0.02);
  min-height: auto;
  background: #fff;
}

.carousel li .h-56 {
  height: auto;
  padding-top: 72%;
}

.popular .carousel li .h-56 {
  padding-top: 64%;
}

.carousel li .rounded-md {
  border-radius: 10px;
}

.carousel li .rounded-md.card,
.carousel li .rounded-md.cheff-card {
  box-shadow: none;
}

.carousel .next:disabled,
.carousel .prev:disabled {
  background: #eaeaea;
  cursor: default;
}

.carousel .prev:not(:disabled) img {
  filter: brightness(2.5);
}

.carousel .next:disabled img {
  filter: brightness(0.5);
}

.heading-with-bar {
  margin-bottom: 30px;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  padding: 0;
  color: #222;
  position: relative;
  text-transform: capitalize;
}

@media all and (max-width: 767px) {
  .heading-with-bar {
    font-size: 20px;
    padding-right: 0;
    line-height: 25px;
  }
}

.heading-with-bar:before {
  position: absolute;
  width: 80px;
  height: 4px;
  border-radius: 14px;
  background-color: #de3530;
  bottom: -10px;
  left: 0;
  content: "";
}

.carousel li .text-base {
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  color: #222;
}

.carousel li .text-black-dark {
  font-size: 14px;
  font-weight: 400;
  color: rgba(34, 34, 34, 0.4);
  line-height: 1;
  margin-top: -6px;
}

.carousel li .cheff-card .text-black-dark {
  margin-top: 0;
}

.carousel li .text-black-dark .text-black {
  font-weight: 500;
  color: rgba(34, 34, 34, 1);
}

.carousel li .text-black-dark img+.text-black {
  margin-top: 2px;
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;

  &+.rating-count {
    margin-top: 0px;
    line-height: 1.25;
  }
}

.carousel li .text-black-dark img {
  margin-top: -4px;
  margin-right: 1px;
}

.carousel li .text-xl {
  color: #de3530;
  font-weight: 600;
  line-height: 1;
}

.carousel li .text-xl .font-semibold {
  font-weight: 700;
}

.carousel li .add {
  background: #f6f6f6;
  min-width: 80px;
  text-align: center;
  line-height: 1.25;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  text-transform: uppercase;
  cursor: pointer;
  color: #222;
}

.carousel li .cheff-card .add {
  font-size: 12px;
}

.carousel li .add.wide {
  width: 100%;
}

.carousel li .add .absolute {
  top: -1px;
  right: 3px;
  font-size: 19px;
  font-weight: 500;
}

.carousel li .cusine-name,
.cheff-card span .cusine-name {
  left: 10px;
  bottom: 10px;
  padding: 4px 10px;
  background: #222;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  border-radius: 10px;
  line-height: 1.25;
  text-transform: capitalize;
}

.carousel li .action {
  align-items: flex-end;
}

.carousel .prev,
.carousel .next {
  width: 36px;
  height: 36px;
  border-radius: 12px;
  background: #222;
  top: 10px;
  right: 15px;
  left: auto;
}

@media all and (max-width: 767px) {

  .carousel .prev,
  .carousel .next {
    top: 3px;
    right: 5px;
  }

  .carousel .prev {
    right: 46px;
  }
}

.carousel .next {
  background: #222;
}

.carousel .prev img,
.carousel .next img {
  margin: 0 auto;
}

.carousel .prev:focus,
.carousel .next:focus {
  outline: 0;
  box-shadow: none;
}

.carousel .prev {
  right: 65px;

  @media all and (max-width: 767px) {
    right: 56px;
  }
}

.carousel li .img-wrap img {
  width: 100%;
  height: 100%;
}

.near-by-meals .carousel li {
  margin-bottom: 30px;
  padding: 0;
  margin-right: 30px;
}

.near-by-meals .carousel li:nth-child(4n) {
  margin-right: 0;
}

.filter-bar {
  border-radius: 20px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
  background-color: #fff;
  padding: 15px 20px 20px;
}

.filter-bar .grid {
  display: flex;
  align-items: center;
}

.filter-bar .grid>div {
  padding-right: 30px;
  position: relative;
  width: 240px;
}

.filter-bar .grid>div.search-field {
  width: calc(100% - 620px);
}

.filter-bar .title {
  font-size: 16px;
  font-weight: 700;
  color: #222;
  margin-bottom: 15px;
  display: block;
  line-height: 20px;
}

.filter-bar .react-dropdown-select,
.filter-bar .form-control {
  height: 48px;
  border-radius: 12px;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  font-size: 14px;
  font-weight: 400;
  padding: 13px 15px 13px 47px;
  @apply font-secondary;
  color: #222;
}

.filter-bar .search-icon {
  position: absolute;
  left: 15px;
  top: 15px;
}

.filter-bar .react-dropdown-select .react-dropdown-select-input {
  position: absolute;
  top: 15px;
  font-size: 14px;
  color: #222;
}

.filter-bar .react-dropdown-select-inpu::placeholder,
.filter-bar .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--cl-gry);
  opacity: 1;
  letter-spacing: -0.2px;
  word-spacing: -0.2px;
  /* Firefox */
}

.filter-bar .react-dropdown-select-inpu:-ms-input-placeholder,
.filter-bar .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--cl-gry);
  letter-spacing: -0.2px;
  word-spacing: -0.2px;
}

.filter-bar .react-dropdown-select-inpu::-ms-input-placeholder,
.filter-bar .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--cl-gry);
  letter-spacing: -0.2px;
  word-spacing: -0.2px;
}

.filter-bar .react-dropdown-select {
  padding-left: 20px;
}

.order-first {
  width: 280px;
}

.order-first+div {
  width: calc(100% - 280px);
  padding-left: 40px;
}

.desc {
  font-size: 14px;
  line-height: 24px;
  color: #222;
  @apply font-secondary;
  word-break: break-word;

  p {
    word-wrap: break-word;
  }
}

.chef-of-month .dd-button-cta--primary-alt {
  min-width: 201px;
}

.chef-of-month .desc {
  max-width: 82%;
}

.med-heading {
  font-size: 24px;
  color: #222;
}

.small-heading {
  font-size: 15px;
  margin-bottom: 20px;
  color: #222;
  font-weight: 700;
}

.row-direction {
  flex-direction: row !important;
}

.watermark {
  bottom: 33px;
  right: 39px;
}

.order-first .relative {
  padding-top: 100%;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  border: 1px solid #ccc;
}

.order-first .relative img {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
}

.order-first .relative img.absolute {
  display: none;
}

.near-by-chef .grid-gap {
  grid-gap: 30px;
  gap: 30px;
}

.cuisine-max-height {
  max-height: 450px;
  overflow: auto;
  margin-bottom: 1rem;
}

.cuisine-max-height.mb-0 {
  margin-bottom: 0;
}

.box-with-shadow {
  padding: 35px;
  border-radius: 22px;
  box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, 0.02);
  background-color: #fff;
  display: flex;
}

.pointer {
  cursor: pointer;
}

.drop-down {
  display: none;
  background: #fff;
  position: absolute;
  right: 0;
  min-width: 247px;
  font-size: 14px;
  top: 26px;
  padding: 22px;
  border-radius: 20px;
  box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.1);
}

#dropdown.drop-down {
  padding: 20px;
  top: 42px;
}

.choose-location #dropdown.drop-down {
  padding: 20px;
  top: 39px;
  right: 0px;
  min-width: 244px;
}

.choose-location #dropdown.drop-down.main-location-drop {
  width: 550px;
  left: -315px;
  top: 43px;
  padding-bottom: 3px;
  z-index: 1;

  @media all and (max-width: 767px) {
    width: 100%;
    left: -134px;
    right: auto;
  }
}

#dropdown.drop-down.main-location-drop-small {
  width: 310px;
  left: 0px;
  top: 57px;
  padding-bottom: 3px;
  z-index: 1;
}

.main-location-drop-small .choose img {
  position: static;
}

.drop-down>* {
  font-size: 14px;
  margin-bottom: 24px;
}

.drop-down>* .dd-button-cta--primary-alt {
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
  font-weight: bold;
  font-size: 16px;
}

.drop-down>*:last-child {
  margin-bottom: 0;
}

.user-drop {
  position: relative;
  margin-bottom: -6px;
  margin-left: 0;
  padding-bottom: 6px;

  .rounded-md {
    border-radius: 10px !important;
    margin-top: 0px;
    max-width: 40px;
    max-height: 40px;
  }

  &.chef-view {
    margin-bottom: -8px;

    @media all and (max-width: 991px) {
      display: none;
    }
  }
}

.user-drop>a {
  display: flex;
  align-items: center;
  height: 50px;
}

.user-drop {
  .drop-down {
    top: 48px;
    right: -15px;
  }

  &:hover .drop-down {
    display: block;
  }
}

.drop-down .choose {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  border-bottom: 1px solid #ececec;
  padding-bottom: 17px;
  margin-bottom: 17px;
  color: #222;
}

.drop-down .choose:last-child {
  border-bottom: 0;
  margin: 0;
}

.drop-down .choose img {
  margin-right: 10px;
}

.drop-down.show {
  display: block;
}

.form-with-btn {
  display: flex;
  gap: 15px;

  .dd-button-cta {
    padding-top: 16px;
    font-weight: 700;
    border-radius: 14px;
    min-width: 149px;

    @media all and (max-width: 767px) {
      display: none;
    }
  }
}

.search-form-row {
  display: flex;
  align-items: center;
  padding: 13px 15px;
  border-radius: 12px;
  background-color: #fff;
  max-width: 654px;
  position: relative;
  width: 100%;
}

.search-form-row>div {
  flex: 1;
}

.search-form-row>div.form-group {
  max-width: 245px;
}

.search-form-row .form-control {
  height: 30px;
  background: none;
  padding: 0 22px;
  padding-right: 1px;
  @apply font-secondary;
  font-size: 14px;
  letter-spacing: -0.2px;
  width: 100%;
  background: #fff !important;

  @media all and (max-width: 767px) {
    background: #f2f2f2 !important;
  }
}

.search-form-row .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #777777;
  opacity: 1;
  /* Firefox */
}

.search-form-row .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #777777;
}

.search-form-row .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #777777;
}

.search-form-row .form-group .icon {
  position: absolute;
  left: -4px;
  top: -3px;
  width: 24px;
  height: 24px;
  cursor: initial;

  &.withBounce {
    cursor: pointer;
  }
}

.search-form-row .form-group .icon.search {
  top: 4px;
  left: 4px;

  &+.form-control {
    padding-left: 30px;
  }

  @media all and (max-width: 767px) {
    left: 18px;

    &+.form-control {
      padding-left: 45px;
    }
  }
}

.search-form-row .form-group {
  position: relative;
  flex: 1;
}

.search-form-row .form-group.location {
  max-width: 205px;
}

.search-form-row .form-group+.form-group {
  border-left: 1px solid #bebebe;
  padding-left: 15px;

  @media all and (max-width: 991px) {
    max-width: 38% !important;
  }
}

.search-form-row .form-control.location {
  padding: 0 28px 0 24px;
  letter-spacing: -0.5px;
  border-right: 1px solid #bebebe;
  border-radius: 0;
}

.home-banner .scroll-down img {
  margin-top: -5px;
  display: inline-block;
  vertical-align: top;
}

.scroll-down {
  color: var(--cl-gry);
  padding-top: 53px;
  @apply font-secondary;
  font-size: 13px;
  scroll-behavior: smooth;
  display: flex;

  @media all and (max-width: 767px) {
    padding-top: 10px;
  }
}

.home-banner .scroll-down a {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 6px 15px 7px;
  border-radius: 20px;
}

.scroll-down span {
  display: block;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  padding-left: 10px;
  color: #222;
}

.choose-location {
  position: absolute;
  right: 12px;
  top: 3px;

  @media all and (max-width: 767px) {
    right: 4px;
  }

  img {
    width: 24px;
    height: 24px;
  }
}

.meal-near-you .carousel li {
  margin-bottom: 30px;
}

.inner-wrap .img-frame {
  padding-top: 71.15%;
  position: relative;
  border-radius: 20px;
  margin-bottom: 30px;
  overflow: hidden;
  border: 1px solid #ccc;

  @media all and (max-width: 767px) {
    margin-bottom: 20px;
  }

  #featured-chefs &,
  #featured-meals & {
    border: none;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      background: rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
}

.inner-wrap {
  position: relative;
  min-height: 100%;
}

.inner-wrap .img-frame img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.inner-wrap .title {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: #d93d2f;
  font-weight: 700;
  background: #fff;
  padding: 10px 15px 8px;
  font-size: 14px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  z-index: 2;
  border-radius: 20px;
  //box-shadow: 0 4px 4px 0 rgb(0, 0, 0, 0.02);
  background-color: #fff;

  @media all and (max-width: 767px) {
    bottom: 8px;
    left: 8px;
  }
}

.inner-wrap .title img {
  margin-right: 10px;
  position: static;
  width: 22px;
  height: 22px;
  margin-top: -3px;
  display: inline-block;
  vertical-align: top;

  @media all and (max-width: 767px) {
    width: 20px;
    height: 20px;
  }
}

.inner-wrap .name {
  font-size: 20px;
  line-height: 25px;
  text-transform: capitalize;
  margin-bottom: 10px;
  font-weight: 700;
}

.inner-wrap .desc {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 20px;

  @media all and (max-width: 767px) {
    line-height: 22px;
    margin-bottom: 13px;
  }
}

.inner-wrap .dd-button-cta--primary-alt {
  font-weight: 700;
  padding: 10px 30px;
  border-radius: 14px;
  margin-bottom: 10px;
}

.inner-wrap .watermark {
  width: 145px;
  height: 116px;
  position: absolute;
  right: 0;
  bottom: 0;

  @media all and (max-width: 767px) {
    width: 101px;
    height: 81px;
  }
}

.inner-wrap .watermark.orange {
  height: 98px;
}

.about-us .vedio-wrap {
  text-align: right;
  max-width: 800px;
  position: relative;
  padding-right: 27px;
  width: 100%;
  margin: 0 auto;
}

.homepage .about-us .vedio-wrap {
  max-width: 480px;
  padding-top: 0;
  overflow: visible;
  border: 0;
  padding-right: 0;

  @media all and (max-width: 1199px) and (min-width: 1024px) {
    .video-bg {
      left: -16px;
    }
  }

  @media all and (max-width: 1023px) and (min-width: 768px) {
    max-width: 46%;

    .video-bg {
      left: -16px;
      max-width: 65px;
      top: -26px;
    }
  }

  &>div {
    position: relative;
  }
}

@media all and (max-width: 479px) {
  .homepage .about-us .vedio-wrap {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

.about-us .vedio-wrap .video-bg {
  position: absolute;
  left: -61px;
  top: -50px;

  @media all and (max-width: 991px) {
    left: -24px;
  }

  @media all and (max-width: 767px) {
    max-width: 64px;
    top: -24px;
    left: 0;
  }

  @media all and (max-width: 479px) {
    left: 0;
  }
}

.about-us .vedio-wrap .video-bg-bottom {
  position: absolute;
  right: -50px;
  bottom: -32px;

  @media all and (max-width: 991px) {
    right: -10px;
  }
}

.about-us .vedio-wrap .video-bg.right {
  top: auto;
  left: auto;
  bottom: -37px;
  right: 0;
}

.about-us .vedio-wrap>div {
  width: 531px;
  margin: 0;
  padding: 0;
  border-radius: 25px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.06);
  border: solid 10px #fff;
  display: inline-flex;
  padding-top: 60.5%;
  position: relative;
  max-width: 100%;
  overflow: hidden;

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media all and (max-width: 767px) {
  .about-us .vedio-wrap>div {
    width: 95%;
    padding-top: 51%;
  }
}

@media all and (max-width: 400px) {
  .about-us .vedio-wrap>div {
    width: calc(100% - 17px);
  }
}

.about-us .data-wrap {
  padding-left: 73px;

  @media all and (max-width: 1199px) {
    padding-left: 30px;
  }

  @media all and (max-width: 991px) {
    padding-left: 20px;
  }
}

.about-us p {
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 25px;

  @media all and (max-width: 767px) {
    line-height: 24px;
    margin-bottom: 20px;
  }
}

.about-us .learn-more {
  height: 48px;
  padding: 10px 34px 14px 34px;
  border-radius: 14px;
  border: solid 2px #de3530;
  font-size: 16px;
  font-weight: bold;
  color: #de3530;
  display: inline-block;
}

.about-us .learn-more:hover {
  background: #de3530;
  color: #fff;
}

.about-us .plyr__control--overlaid svg {
  visibility: hidden;
}

.icon {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 12px;
  cursor: pointer;
  font-size: 0;
}

.icon.minus {
  background: center no-repeat;
}

.icon.plus {
  left: auto;
  right: 12px;
  background: center no-repeat;
}

.qty-form .qty {
  display: block;
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 34px;
}

.checkout-summary .box-with-shadow {
  flex-direction: column;
  padding: 20px;
  top: 0px;
  position: relative;
  &.padding-h70{
    padding: 15px 20px;
  }
}

// .checkout-summary .box-with-shadow.row-direction {
//   position: relative;
// }

.checkout-summary .box-with-shadow.row-direction .check-container {
  position: absolute;
  right: 10px;
  top: 20px;
  margin: 0;
}

@media all and (max-width: 991px) {
  .checkout-summary .box-with-shadow {
    padding: 20px;
    margin-left: 0;
  }
}

.price-row li p {
  padding-bottom: 20px;
  line-height: 1;
  font-size: 14px;
  @apply font-secondary;
}

.price-row li p.no-padd {
  padding-bottom: 0;
}

.price-summary .total-price {
  padding: 20px 0 0;
  border-top: 1px solid rgba(34, 34, 34, 0.1);
}

.price-summary  .distriution-price {
  padding: 20px 0 0;
}

.address-close-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 1.5rem;
  font-weight: bold;
}

.orders-row .address-close-btn {
  top: 7px;
}

.price-summary .total-price strong {
  font-size: 14px;
  font-weight: 700;
  @apply font-secondary;
}

.coupon-box {
  border-top: 1px solid rgba(34, 34, 34, 0.1);
  border-bottom: 1px solid rgba(34, 34, 34, 0.1);
  padding: 20px 0;
}

.coupon-box strong {
  padding-bottom: 15px;
  display: block;
  font-size: 14px;
  line-height: 1;
  font-weight: 700;
}

.form-group {
  flex: 1;
}

.form-group.otp {
  flex: 1;
  text-align: left;
  margin-bottom: 30px;
}

.form-control {
  height: 40px;
  padding: 11px 15px;
  background: #f2f2f2 !important;
  border-radius: 12px;
}

.order-btn-wrap {
  padding-top: 20px;
  font-size: 16px;
  font-weight: 700;
}

.order-btn-wrap .dd-button {
  font-weight: 700;
  font-size: 16px;
  width: 100%;
}

.chef-of-month .carousel .carousel__slider-tray-wrapper {
  margin-left: 0;
  margin-right: 0;
}

.chef-of-month .carousel .prev,
.chef-of-month .carousel .next {
  right: 16px;
}

.chef-of-month .carousel .prev {
  right: 65px;
}

.breadcrumb {
  font-size: 14px;
  color: var(--cl-gry);
  line-height: 20px;
  margin: 20px 0 20px;
}

.breadcrumb .link,
.breadcrumb a {
  @apply font-secondary;
  position: relative;
  padding-right: 8px;
  margin-right: 4px;
  cursor: pointer;
  color: #777;
}

.breadcrumb .link:hover,
.breadcrumb a:hover {
  text-decoration: underline;
}

.breadcrumb .link::after,
.breadcrumb a::after {
  content: "/";
  margin-left: 3px;
  position: absolute;
  right: 0px;
}

.breadcrumb span {
  color: #222;
  text-transform: capitalize;
  @apply font-secondary;
}

.cart-postion {
  top: -9px;
  right: -9px;
  line-height: 20px;
  text-align: center;
  width: 20px;
  height: 20px;
}

.notification-postion {
  right: -30px;
  border: 2px solid white;
  top: -1px;
}

.form-group .otp-field {
  width: 65px;
  height: 65px;
  text-align: center;
  border-radius: 12px;
  margin-right: 22px;
  font-size: 20px;
  border: 1px solid rgba(34, 34, 34, 0.2);
}

.cusotm-checkbox.form-group ul {
  display: flex;
  flex-wrap: wrap;
  max-height: 380px;
  overflow-y: auto;

  @media all and (max-height: 700px) {
    max-height: 300px;
  }
}

.cusotm-checkbox.form-group ul li {
  margin-right: 10px;
  min-width: 96px;

  @media all and (max-width: 479px) {
    min-width: 90px;
  }
}

.cusotm-checkbox.form-group ul li label {
  // border-radius: 30px;
  position: relative;
  line-height: 36px;
  font-weight: 500;
  font-size: 14px;
  height: 36px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  margin-bottom: 10px;
  padding: 0 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  &.active{
    color: #de3530;
  }
}

.cusotm-checkbox.form-group ul li label input[type="checkbox"] {
  width: 100%;
  background: none;
  appearance: none;
  border-radius: 10px;
  border: 1px solid #ececec;
  height: 100%;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
}

.cusotm-checkbox.form-group ul li label input[type="checkbox"]:checked {
  border-color: #de3530;
}
.cusotm-checkbox.form-group ul li label input[type="checkbox"]:checked ~ span{
  color: red;
}

.submitDisable {
  cursor: not-allowed;
  pointer-events: none;
}

.submitEnanble {
  cursor: pointer;
  pointer-events: all;
}

.meal-truncate {
  white-space: initial;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 60px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* Custom Css */

.title-title {
  font-size: 20px;
  font-weight: bold;
  color: #222;
  margin-bottom: 20px;
}

.arrow-width {
  width: 12px;
  height: 12px;
}

.w-100 {
  width: 100%;
}

.form-container {
  margin-bottom: 20px;
}

.form-container.mb-0 {
  margin-bottom: 0;
}

// .form-container:last-child {
//   margin-bottom: 0px;
// }

.form-container>.dd-form-field {
  --color-bg: #f2f2f2;
  background-color: var(--color-bg);
  border-radius: 12px;
  border-color: var(--color-bg);
  margin-left: 0px;
  min-height: 48px;
}

.form-container>.dd-form-field select {
  background-color: var(--color-bg);
}

.label-text {
  font-size: 14px;
  color: #222;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.tooltip-trigger {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1;
}

.bottom-change {
  bottom: 0px !important;
}

.title-change {
  font-size: var(--text-size);
  font-weight: bold;
  color: #de3530;
  text-transform: capitalize;
  position: absolute;
  top: 22px;
  right: 30px;

  @media all and (max-width: 991px) and (min-width: 768px) {
    right: 24px;
    top: 18px;
  }
}

/* Review Rating */

.cs-width {
  width: calc(100% - 79px);
}

.header-details strong {
  margin-right: auto;
  font-size: 14px;
  font-weight: bold;
  color: #222;
}

.header-details time {
  font-size: 14px;
  font-weight: 500;
  color: var(--cl-gry);
}

.user-content {
  font-size: 14px;
  color: #222;
  text-align: left;
}

.review-section .user-content {
  margin-top: 5px;
}

.review-section ul.flex li {
  margin-right: 3px;
}

.card-card {
  padding: 20px;
  border-radius: 8px;
  background-color: #f6f6f6;
  max-width: 632px;
  margin-left: 1rem;
  overflow-y: auto;
  max-height: 125px;
  height: fit-content;
}

.card-card.request-card {
  overflow: visible;
  margin-left: 6rem;
  margin-top: 1rem;
  width: 100%;
  padding-right: 31px;
}

@media all and (max-width: 767px) {
  .card-card.request-card {
    margin-left: 0;
  }
}

.small-text {
  font-size: 14px;
  font-weight: bold;
  color: #de3530;
  flex: 1;
}

.card-card-body {
  overflow-y: auto;
  max-height: 65px;
}

.card-card-body p {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  color: #222;
  @apply font-secondary;
}

.card-card-header .small-text {
  line-height: 18px;
  margin-bottom: 5px;
}

.submenu {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
}

.submenu .dropdown-nv {
  display: none;
}

.submenu:hover .dropdown-nv {
  display: block;
  top: 30px;
}

.dropdown-nv {
  padding: 15px 20px;
  min-width: 150px;
  border-radius: 10px;
  box-shadow: 0 2px 23px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  position: absolute;
  right: 0px;
  //top: 48px;
  z-index: 1;
}

.nv-items {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: #222;
}

.nv-items:last-child {
  margin-bottom: 0px;
}

.nv-items svg {
  margin-right: 12px;
  @media (max-width: 767px){
    margin-right: 0px;
    margin-left: 10px;
  }
}

.removecartfun-btn{
  display: flex;
  align-items: center;
  margin-left: 16px;
  @media (max-width: 767px){
    margin-left: 0px;
  }
}

/* Modal */
.modal-card {
  padding: 20px 20px 30px 30px;
  border-radius: 20px;
  background-color: #fff;
}

.modal-card .textarea-control {
  @apply font-secondary;
  margin-bottom: 30px;
}

.head-label {
  font-size: 16px;
  font-weight: bold;
  color: #222;
  margin-bottom: 20px;
  line-height: 20px;
}

.textarea-control {
  border-radius: 12px;
  background-color: #f2f2f2;
  width: 100%;
  min-height: 150px;
  resize: none;
  padding: 20px;
  font-size: 14px;
  color: var(--cl-gry);
  margin-bottom: 10px;
}

.textarea-control.smaller {
  min-height: 118px;
}

.add-btn {
  padding: 8px 15px;
  border-radius: 10px;
  background-color: #de3530;
  color: #fff;
  font-size: 16px;
  min-width: 90px;
  font-weight: bold;
}

/* End */
.filter-bar .react-dropdown-select .react-dropdown-select-input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #444;
  opacity: 1;
  /* Firefox */
}

.filter-bar .react-dropdown-select .react-dropdown-select-input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #444;
}

.filter-bar .react-dropdown-select .react-dropdown-select-input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #444;
}

/* Modal */

.modal-dd-center {
  background-color: rgba(255, 255, 255, 1);
  padding: 30px;
  max-width: 500px;
  width: 100%;
  left: 50%;
  top: 50%;
  border: 2px solid white;
  max-height: 90vh;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  &.bottom-modal{
    @media (max-width: 480px){
      left: auto;
      top: auto;
      bottom: 0;
      transform: unset;
    }
  }

  .dd-button-cta--primary-alt {
    height: 40px;
    padding: 0;
    // min-width: 166px;
    font-size: 16px;
    font-weight: 700;
  }

  &.confirm {
    max-width: 400px;

    .desc {
      text-align: center;
      max-width: 70%;
      margin: 0 auto;
    }
  }

  .btns-wrap {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    .dd-button-cta {
      min-width: 142px;
      height: 40px;
      padding: 6px 20px 10px;
      border-radius: 10px;
      border: solid 1px #de3530;
      background-color: #fff;
      font-size: 16px;
      font-weight: bold;
      color: #de3530;

      &.dd-button-cta--primary-alt {
        background: #de3530;
        color: #fff;
        border-color: transparent;
      }

      &+.dd-button-cta {
        margin-left: 15px;
      }
    }
  }
}

.modal-dd-center .modal-card {
  padding: 0;
}

.modal-dd-center .modal-card .modal-head {
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(34, 34, 34, 0.1);
  margin-bottom: 20px;
}

.modal-dd-center .modal-card .modal-head .close-btn {
  margin: -10px -10px 0 0;
  vertical-align: top;
}

.modal-dd-center .modal-card .modal-head.no-space {
  border: 0;
  padding: 0;
  margin-bottom: 10px;
}

.modal-card .modal-head h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
}

.modal-dd-center .loader {
  width: 2rem;
  height: 2rem;
  margin: 0 auto !important;

  .innerloader div {
    width: 27px;
    height: 27px;
    left: 2px;
    top: 2px;
    transform-origin: center;
    box-shadow: 0 3px 0 0 #de372f;
  }
}

.loader-wrap .loader {
  margin: auto !important;
}

label.head-label {
  font-size: 14px;
  font-weight: 400;
  color: #222;
  margin-bottom: 10px;
  display: block;
  line-height: 1;
  @apply font-secondary;
}

.modal-dd-center .container {
  padding: 0;
}

.modal-dd-center .container .modal-cards {
  padding: 0;
}

.add-instruction {
  font-size: var(--text-size);
  font-weight: bold;
  color: #de3530;
  text-transform: capitalize;
  margin-left: 50px;
  position: absolute;
  bottom: 25px;
  left: 288px;
  font-size: 14px;

  @media all and (max-width: 767px) {
    position: relative;
    left: 0;
    top: 0;
    margin: 15px 0 0;
    bottom: 0;
  }
}

.meal-info .add-instruction {
  position: relative;
  left: 0;
  bottom: 0;
  margin-left: 0;
  margin-bottom: 22px;
}

@media all and (max-width: 991px) and (min-width: 768px) {
  .add-instruction {
    margin-left: 6.5rem;
    margin-top: 9px;
  }
}

.special-request-modal .modal-head {
  padding: 0 !important;
  border: none !important;
}

.dd-remove-btn {
  padding: 0px;
}

.txt-14 {
  font-size: 14px;
  font-weight: bold;
  color: #222;
}

.txt-12 {
  font-size: 12px;
  color: #222;
}

.w-863 {
  max-width: 863px;
}

.txt-24 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.46;
  text-align: center;
  color: #222;
}

.l-10 {
  margin-left: 10px;
}

/* Account */
.text-20p {
  font-size: 24px !important;
  display: inline-block;
  margin-bottom: 20px;
}

.text-20p::before {
  bottom: 0px !important;
  width: 100%;
}

.h1-title {
  font-size: 20px;
  font-weight: bold;
  color: #222;
  margin-bottom: 20px;
}

.account-card {
  padding: 30px;
  border-radius: 22px;
  box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, 0.02);
  background-color: #fff;
  display: flex;
  justify-content: space-between;

  @media all and (max-width: 767px) {
    position: relative;
    padding: 20px;
  }
}

.accout-body .image-round {
  width: 120px;
  height: 120px;
  border-radius: 20px;
  object-fit: cover;
  margin-right: 15px;
}

.txt-15b {
  font-size: 15px;
  color: #222;
}

.icon-box-text {
  margin-bottom: 12px;
  flex-wrap: wrap;
}

.icon-box-text:last-child {
  margin-bottom: 0;
}

.icon-box-text span img {
  margin-right: 10px;
}

.icon-box-text span img.w-6 {
  min-width: 1.5rem;
}

.accoutn-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 0.85;
  color: #222;
  margin-bottom: 6px;
  padding-right: 140px;
  text-transform: capitalize;

  @media all and (max-width: 479px) {
    padding-right: 0;
  }

  .experience {
    margin-left: 19px;
    font-size: 15px;
    font-weight: 400;
    @apply font-secondary;
  }
}

.account-card .account-right button {
  padding: 8px 21px 8px 20px;
  border-radius: 10px;
  background-color: #de3530;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  color: #fff;

  &:hover {
    background: #b72a27;
  }

  @media all and (max-width: 678px) {
    position: absolute;
    top: 15px;
    right: 15px;
  }
}

.add-cls {
  font-size: 16px;
  font-weight: 600;
  color: #de3530;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.Home-My-Account {
  width: 140px;
  height: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1.43;
  color: #222;
  margin-top: 40px;

  &.block {
    width: auto;
  }
}

.Home-My-Account .text-style-1 {
  color: var(--cl-gry);
  margin-right: 3px;
}

.mb-14 {
  margin-bottom: 40px;
}

.w-mx-full {
  max-width: 100% !important;
}

/* Edit Profile */
.edit-sec {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.cl-width {
  width: calc(100% - 211px);
}

.ed-image {
  width: 180px;
  height: 180px;
  border-radius: 20px;
  object-fit: cover;
}

.edit-sec input {
  position: absolute;
  top: 0;
  height: 100%;
  width: calc(100% + 150px);
  opacity: 0;
  cursor: pointer;
  left: -150px;
}

.input-cs {
  padding: 15px;
  border-radius: 12px;
  background-color: #f2f2f2;
  width: 100%;
  font-size: 14px;
  color: #222;
}

.number-input {
  border-radius: 12px;
  padding: 15px 20px;
  background-color: #f2f2f2;
  font-size: 14px;
  color: #222;

  &.diabled {
    background-color: #f9f9f9;

    input {
      opacity: 0.5;
    }
  }
}

.account-info .number-input>.input-cs-number {
  padding-right: 15px;
  background: none;
}

.number-input select {
  background-color: #f2f2f2;
  color: var(--cl-gry);
  background-image: none !important;
}

.line {
  display: block;
  width: 2px;
  background-color: #222222;
  margin-left: 5px;
  margin-right: 6px;
  height: 14px;
}

.input-cs-number {
  width: 100%;
  background-color: #f2f2f2;

  &:disabled {
    cursor: not-allowed;
  }
}

.img-info {
  font-size: 12px;
  color: #222;
  max-width: 180px;
  width: 100%;
  display: inline-block;
  margin-top: 10px;
}

.cs-select {
  border-radius: 12px;
  padding: 15px 20px 17px;
  background-color: #f2f2f2;
  font-size: 14px;
  color: #222;
  width: 100%;
  text-transform: capitalize;
  appearance: none;
}

.update-btn {
  padding: 12px 45px;
  border-radius: 10px;
  background-color: #de3530;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.text-btn {
  font-size: 14px;
  font-weight: bold;
  color: #de3530;
  text-transform: capitalize;
}

.text-btn-disabled {
  font-size: 16px;
  font-weight: bold;
  color: #eb8c8b;
  text-transform: capitalize;
  pointer-events: none;
}

.modal-cards {
  padding: 30px;
  border-radius: 20px;
  background-color: #fff;
  max-width: 500px;
  width: 100%;
}

.mod-header {
  padding-bottom: 30px;
  border-bottom: 1px solid #2222221a;
  margin-bottom: 17px;

  &+div:not(.address-list-row) {
    height: auto !important;
  }
}

.mod-title {
  font-size: 14px;
  color: #222;
  margin-bottom: 20px;
}

.mod-header-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  color: #222;
}

.otp-btn {
  padding: 9px 45px 11px 46px;
  border-radius: 10px;
  background-color: #de3530;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-top: 19px;
  cursor: pointer;
}

.from-group-input {
  display: flex;
  margin-bottom: 40px;
}

.from-group-input input {
  width: 65px;
  height: 65px;
  margin: 40px 22px 40px 0;
  opacity: 0.2;
  border-radius: 12px;
  border: solid 1px #222;
  background-color: #fff;
  margin-right: 22px;
}

.from-group-input input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.r-text {
  font-size: 14px;
  color: var(--cl-gry);
}

.r-text a, .r-text button {
  margin-left: 5px;
  color: #de3530;
  font-weight: bold;
}

.mt-0 {
  margin-top: 0px;
}

.inp-pd input {
  padding: 15px 20px;
}

.font-12 {
  font-size: 12px;
  color: var(--cl-gry);
}

.cs-check {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.cs-check input {
  width: 100%;
  height: 100%;
}

.dis-btn {
  background-color: #de353080;
}

.bredcum {
  font-size: 14px;
  line-height: 1.43;
  color: var(--cl-gry);
  display: block;
  margin-bottom: 20px;
  @apply font-secondary;
}

.bredcum.top-margin {
  margin-top: 40px;
}

.bredcum span:last-child {
  color: #222;
}

/* End */

.coupon-form {
  height: 40px;
  padding-left: 12px;
  margin-right: -6px;
  background: #f2f2f2 !important;
  border-radius: 12px;
}

.empty .box-with-shadow {
  box-shadow: none;
}

.empty.box-with-shadow {
  display: block;
}

.empty.box-with-shadow h2 {
  font-size: 1.25rem;
}

/* Home search */
.mx-235px {
  max-width: 235px !important;
}

.mx-628px {
  max-width: 605px !important;
}

.mx-h-166px {
  max-height: 166px !important;
}

.pt-0px {
  padding-top: 0px !important;
  /* border-bottom: 1px solid #e2e8f0; */
  /* margin-bottom: 16px; */
}

.pb-0px {
  padding-bottom: 0px !important;
}

.p-text-12 {
  font-size: 12px;
  line-height: 1.67;
  color: #222;
}

.mb-30px {
  margin-bottom: 10px;
}

@media (max-width: 940px) {
  .sm-container {
    flex-direction: column;
  }

  .sm-ml-0 {
    margin-left: 0px;
    margin-top: 30px;
  }

  .sm-order-2 {
    order: 2;
  }

  .sm-h-ml-0 {
    margin-left: 0px;
  }
}

@media (max-width: 768px) {
  .sm-app-container .data-wrap {
    margin: 0px auto;
  }

  .ex-sm-none {
    display: none;
  }

  .sm-width-full {
    width: 100%;
  }
}

@media (max-width: 678px) {
  .account-card {
    flex-direction: column;
  }

  .accout-body {
    margin-bottom: 20px;
  }
}

@media all and (max-width: 767px) {

  .mod-body .gap-6,
  .account-info.cl-width .gap-6 {
    grid-gap: initial;
    gap: inherit;
  }

  .mod-bod .form-container:last-child,
  .account-info.cl-width .form-container:last-child {
    margin-bottom: 20px;
  }

  .btn-wrap {
    align-items: flex-start;
    flex-direction: column;
    padding: 15px 0 0;
  }

  .btn-wrap .otp-btn {
    width: 100%;
    margin-top: 15px;
  }
}

@media all and (max-width: 639px) {
  .account-info.cl-width {
    width: 100%;
  }
}

@media (max-width: 478px) {
  .accout-body {
    flex-direction: column;
  }

  .account-info {
    margin-top: 20px;
  }

  .account-info .icon-box-text {
    flex-direction: column;
  }

  .account-info .icon-box-text.phone {
    flex-direction: row;
  }

  .account-info .min-width-cusines {
    margin: 10px 0 0;
  }
}

.account-info {
  @media all and (max-width: 991px) {
    .icon-box-text {
      flex-direction: column;

      &.phone {
        flex-direction: row;
      }
    }
  }
}

.react-datepicker-popper {
  z-index: 1;
  padding: 28px 28px 20px 28px;
  border-radius: 20px;
  box-shadow: 0 2px 23px 0 #ededed;
  background-color: #fff;
}

.react-datepicker-popper .react-datepicker__header,
.react-datepicker-popper .react-datepicker {
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
}

.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
  top: 4px !important;
}

.react-datepicker-popper .react-datepicker__current-month {
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  color: #222;
  margin-bottom: 12px;
  @apply font-secondary;
}

.react-datepicker-popper .react-datepicker__day-name,
.react-datepicker-popper .react-datepicker__day,
.react-datepicker-popper .react-datepicker__time-name {
  width: 30px;
  line-height: 30px;
  border-radius: 100% !important;
  font-weight: 500;
  font-size: 15px;
  color: #222;
  margin-right: 15px;
  @apply font-secondary;
}

.react-datepicker-popper .react-datepicker__day-name:last-child,
.react-datepicker-popper .react-datepicker__day:last-child,
.react-datepicker-popper .react-datepicker__time-name:last-child {
  margin-right: 0;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled .react-datepicker-popper .react-datepicker__day--selected {
  opacity: 0.2;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker-popper .react-datepicker__day--selected,
.react-datepicker-popper .react-datepicker__day--in-selecting-range,
.react-datepicker-popper .react-datepicker__day--in-range,
.react-datepicker-popper .react-datepicker__month-text--selected,
.react-datepicker-popper .react-datepicker__month-text--in-selecting-range,
.react-datepicker-popper .react-datepicker__month-text--in-range,
.react-datepicker-popper .react-datepicker__quarter-text--selected,
.react-datepicker-popper .react-datepicker__quarter-text--in-selecting-range,
.react-datepicker-popper .react-datepicker__quarter-text--in-range,
.react-datepicker-popper .react-datepicker__year-text--selected,
.react-datepicker-popper .react-datepicker__year-text--in-selecting-range,
.react-datepicker-popper .react-datepicker__year-text--in-range {
  background-color: #de3530 !important;
  color: #fff !important;
}

.react-datepicker-popper .react-datepicker__triangle {
  display: none;
}

.react-datepicker-popper .react-datepicker__month {
  margin: 0;
}

.react-datepicker-popper .react-datepicker__day-names,
.react-datepicker-popper .react-datepicker__week {
  margin: 0 -5px;
  text-align: left;
}

.react-datepicker-popper .react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 0;
}

.with-smaller-width {
  max-width: 1050px;
  margin: 0 auto;
}

.inner-wrap .form-wrap .dd-button-cta--primary-alt {
  margin-bottom: 0;
  border-radius: 10px;
  height: 48px;
  font-size: 16px;
}

.orders-row {
  margin-bottom: 50px;
}

.orders-row:last-child {
  margin-bottom: 0;
}

.orders-row h3 {
  font-size: 20px;
  color: #222;
  margin-bottom: 20px;
  font-weight: 700;
}

.orders-row .box-with-shadow {
  margin-bottom: 20px;
}

@media all and (max-width: 767px) {
  .orders-row .box-with-shadow .item-row {
    align-items: flex-start;
  }

  #my-orders .breadcrumb+.container {
    padding: 0;
  }
}

@media all and (max-width: 479px) {
  .orders-row .box-with-shadow {
    padding: 20px;
  }
}

.orders-row .box-with-shadow:last-child {
  margin-bottom: 0;
}

.row-header {
  padding: 15px 0;
  margin-top: -35px;
  border-bottom: 1px solid #e6e6e6;
  line-height: 1;
}

.price-qty span,
.order-info span {
  font-size: 14px;
  @apply font-secondary;
  color: #222;
  line-height: 1;
  display: inline-block;
}

.order-details {
  @media all and (min-width: 768px) {
    .checkout-summary {
      margin-top: 0;
    }
  }

  @media all and (max-width: 767px) {

    .col-span-2,
    .col-span-5 {
      grid-column: span 12 / span 12;
      padding-right: 0 !important;
    }
  }
}

.order-info span.lighter {
  color: #777;
}

.price-qty span.bigger {
  font-size: 15px;
}

.price-qty span+span,
.order-info span+span {
  margin-left: 40px;
}

@media all and (max-width: 991px) {

  .price-qty span+span,
  .order-info span+span {
    margin: 8px 0 0;
  }

  .order-info span,
  .price-qty span {
    display: block;
  }
}

.orders-row .text-link {
  font-size: 15px;
  color: #de3530;
  font-weight: 700;
  white-space: nowrap;
}

.orders-row .row-header .text-link {
  font-size: 16px;
  white-space: nowrap;
}

.row-header .text-link:hover {
  text-decoration: underline;
}

.item-row {
  border-bottom: 1px solid #e6e6e6;
  padding: 15px 0;
}

.item-row:last-child {
  border: 0;
  padding-bottom: 0;
}

.item-img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.item-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.item-detail {
  width: calc(100% - 80px);
  padding-left: 20px;
}

.item-detail.with-cta {
  position: relative;
  padding-right: 140px;
}

.item-detail.with-cta .dd-button-cta {
  height: 40px;
  font-size: 16px;
  font-weight: 700;
  padding: 5px;
  min-width: 126px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

@media all and (max-width: 767px) {
  .item-detail.with-cta {
    padding-right: 0;
    display: flex;
    flex-direction: column;
  }

  .item-detail.with-cta .dd-button-cta {
    position: relative;
    margin: 15px 0 0;
    order: 3;
    max-width: 150px;
  }
}

.item-detail.with-cta .dd-button-cta.top-14 {
  top: 14px;
  margin: 0;

  @media all and (max-width: 767px) {
    position: relative;
    top: 0;
    margin: 15px 0 0;
  }
}

.item-detail .title {
  font-size: 16px;
  font-weight: 700;
  display: block;
  margin-bottom: 16px;
}

.price-qty {
  font-size: 14px;
}

.price-qty .price strong {
  font-weight: 700;
  color: #de3530;
  font-size: 18px;
  @apply font-secondary;
}

.price-qty .price strong.smaller {
  font-size: 15px;
  color: #222;
}

.price-qty .qty strong {
  font-weight: 600;
  @apply font-secondary;
}

.mini-title {
  font-size: 20px;
  font-weight: 700;
  color: #222;
  margin-bottom: 20px;
}

.bg-password {
  padding: 30px;
  border-radius: 22px;
  box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, 0.02);
  background-color: #fff;
  margin-bottom: 5rem;
}

.form-input {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.form-input .dd-form-field {
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
  margin-bottom: 40px;
}

@media all and (max-width: 767px) {
  .bg-password {
    padding: 20px;
  }

  .form-input .dd-form-field {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .form-wrap .dd-button-cta--primary-alt {
    width: 100%;
  }
}

.input-field .dd-form-field.dd-form-field--transparent input {
  border-radius: 12px;
}

/* Notification */
.main-heading {
  margin-top: 100px;
  margin-bottom: 50px;
}

.content-container {
  padding: 20px 20px 25px 25px;
  border-radius: 22px;
  box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, 0.02);
  background-color: #fff;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.content-container .image-container {
  width: 60px;
  height: 60px;
  margin: 5px 20px 0 0;
  border-radius: 10px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-container .content-content {
  width: calc(100% - 180px);
  font-size: 14px;
  line-height: 1.79;
  color: #222;
}

.content-container .content-content h5 {
  @apply font-secondary;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
}

.content-container .content-content p {
  @apply font-secondary;
  margin-bottom: 0;
}

.content-container .content-last {
  margin-left: auto;
}

.content-container .time {
  // font-secondary;
  font-size: 14px;
  text-align: right;
  color: #777;
}

.special-request {
  width: 100%;
  margin: 10px 0 0;
  padding: 10px 15px 10px 10px;
  border-radius: 8px;
  background-color: #f6f6f6;
}

.rating-details-request {
  width: 100%;
  margin-top: 10px;
  padding: 10px 15px 10px 10px;
  border-radius: 8px;
  background-color: #f6f6f6;
}

.special-request strong {
  font-size: 14px;
  color: red;
  display: block;
  margin-bottom: 5px;
  line-height: 1;
  font-weight: 700;
}

.box-with-shadow .special-request p {
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.box-with-shadow .special-request p.small {
  font-size: 12px;
  line-height: 1.67;
}

.alert {
  position: fixed;
  top: 76px;
  left: calc(50% - 238px);
  width: 100%;
  max-width: 476px;
  padding: 0.531rem 3rem 0.531rem 1rem;
  border: 1px solid transparent;
  border-radius: 10px;
  @apply font-secondary;
  font-size: 14px;
  font-weight: 600;

  @media all and (max-width: 767px) {
    width: 90%;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.alert-success {
  background-color: #ccffc2;
  border-color: #ccffc2;
  color: #3aa724;
}

.alert-danger {
  background-color: #fddcda;
  border-color: #fddcda;
  color: #de3530;
}

.fade {
  transition: opacity 0.15s linear;
}

.btn-close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 2;
  padding: 2px 1rem;
  font-size: 2.5rem;
  line-height: 0.8;
}

.payment-box {
  padding: 30px 30px 20px;
  border-radius: 22px;
  box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, 0.02);
  background-color: #fff;
}

.cc-with-radio {
  display: flex;
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  border: solid 2px #ebebeb;
  align-items: flex-start;
  width: 100%;
  max-width: calc(50% - 1rem);
  min-height: 0;

  .title {
    font-size: 20px;
    line-height: 25px;
    padding-bottom: 10px;
    font-weight: 600;
    display: block;
    text-transform: capitalize;
  }

  .address-title {
    font-size: 14px;
    line-height: 20px;
    @apply font-secondary;
  }
}

.dd-modal .address-list-row {
  height: auto !important;
  max-height: 50vh;
  margin-bottom: 20px !important;
}

.dd-modal .address-list-row+.justify-between {
  align-items: center;
}

.dd-modal .address-list-row+.justify-between .add-instruction {
  position: relative;
  left: 0;
  bottom: 0;
  margin: 0;
}

.address-list-row .cc-with-radio {
  padding: 25px 0 24px;

  &:last-child {
    border-bottom: 0 !important;
  }
}

.gift-card .cc-with-radio {
  align-items: flex-start;
}

.gift-card .details-container .cc-section.card-wrap .submenu {
  top: 0;
}

.cc-with-radio .details-container .cc-section {
  padding: 0 10px 0 10px;
}

.payment-box .cc-with-radio .details-container .cc-section {
  padding-right: 0;
  box-shadow: none;
}

.cc-with-radio .details-container .title {
  font-weight: bold;
  font-size: 15px;
}

.cc-with-radio .btn-cls {
  width: 100%;
  min-height: 0;
  font-size: 16px;
  font-weight: 600;
  color: #de3530;
}

/* custom radio */

/* The radio container */
.radio-container {
  display: block;
  position: relative;
  padding-left: 18px;
  margin-bottom: 18px;
  cursor: pointer;
  user-select: none;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border-radius: 50%;
  border: solid 1px #de3530;
}

.radio-container:hover input~.checkmark,
.check-container:hover input~.checkmark {
  border-color: #de3530;
}

.radio-container input:checked~.checkmark,
.check-container input:checked~.checkmark {
  background-color: #de3530;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-container input:checked~.checkmark:after,
.check-container input:checked~.checkmark:after {
  display: block;
}

.radio-container .checkmark:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

/* Custom checkbox */

.check-container {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 30px;
  user-select: none;
  &.margin-20{
    margin-bottom: 20px;
  }
}

.check-container input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.check-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border-radius: 3px;
  border: solid 1px #de3530;
  background-color: #fff;
  &.top-5{
    top: 5px;
  }
}

.check-container .checkmark:after {
  left: 5px;
  top: 1px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%) !important;
  background-position: calc(100% - 20px) calc(1.5em + 2px),
    calc(100% - 15px) calc(1.5em + 2px) !important;
  background-size: 5px 5px, 5px 5px !important;
  background-repeat: no-repeat !important;
  background: #f2f2f2 url(../images/icons/drop-aero.svg) calc(100% - 9px) center no-repeat !important;
}

.payment-box ul {
  display: flex;
  flex-wrap: wrap;
}

.payment-box ul li {
  width: calc(50% - 1rem);

  @media all and (max-width: 767px) {
    width: 100%;

    &:nth-child(2n + 2) {
      margin-left: 0;
    }
  }

  .cc-with-radio {
    max-width: 100%;

    &.add-card {
      min-height: 100%;
      align-items: center;
    }
  }
}

.payment-box.gift-card ul li .check-container {
  margin-top: 3px;
}

.payment-box ul li:nth-child(2n + 2) {
  margin-left: 2rem;

  @media all and (max-width: 767px) {
    margin-left: 0;
  }
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  margin-left: 10px;
  right: 50px;
  border-radius: 3px;
}

.scrollbar::-webkit-scrollbar-track {
  background: #eaeaea;
  border-radius: 3px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #434344;
  border-radius: 3px;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #434344;
  border-radius: 3px;
}

.form-max-hight {
  max-height: 70vh;
  overflow-y: auto;
}

.wp-con {
  max-width: 180px;
}

.mod-body {
  max-height: 70vh;
  overflow-y: auto;
  margin-right: -15px;
  padding-right: 15px;
  margin-bottom: -15px;
  padding-bottom: 15px;
}

.text-primary {
  color: #de3530 !important;
}

.min-height-31 {
  min-height: 31px;
}

.react-datepicker__day--keyboard-selected {
  background: initial !important;
  color: initial !important;
}

.cnacle-btn {
  padding: 12px 45px;
  border-radius: 10px;
  background-color: #fff;
  font-size: 16px;
  font-weight: bold;
  color: #d93d2f;
  margin-right: 20px;
  border: 1px solid #e2e8f0;
}

.min-height-39 {
  min-height: 39px;
}

.social-login-link {
  margin-bottom: 15px;
}

.social-login-link .download-button a {
  margin-bottom: 15px;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
    width: calc(50% - 10px);
  }
}

.bottom-signup-button {
  margin-bottom: 20px;

  @media screen and (min-width: 992px) {
    margin-bottom: 0;
  }
}

.search-form .absolute.pac-container,
.search-form-row .absolute.pac-container {
  width: 326px !important;

  @media all and (max-width: 767px) {
    width: 100% !important;
  }
}

.pac-container.pac-logo {
  transform: translateY(8px) translateX(-14px);
  border-radius: 6px;

  @media all and (max-width: 767px) {
    width: calc(100% - 32px) !important;
  }
}

.all-meals {
  .pac-container.pac-logo {
    transform: translateY(8px) translateX(-32px);

    @media all and (max-width: 767px) {
      width: calc(100% - 60px) !important;
    }
  }

  &.iszip {
    .pac-container.pac-logo {
      transform: translateY(68px) translateX(-32px);
    }
  }

  .search-form .absolute.pac-container,
  .search-form-row .absolute.pac-container {
    width: 386px !important;

    @media all and (max-width: 767px) {
      width: 100% !important;
    }
  }
}

.homepage {
  .pac-container.pac-logo {
    transform: translateY(8px) translateX(-14px);
    border-radius: 6px;
  }

  &.iszip {
    .pac-container.pac-logo {
      transform: translateY(68px) translateX(-14px);
    }
  }

  .search-form .absolute.pac-container,
  .search-form-row .absolute.pac-container {
    width: 326px !important;

    @media all and (max-width: 767px) {
      width: 100% !important;
    }
  }
}

.all-chefs {
  .pac-container.pac-logo {
    transform: translateY(8px) translateX(-32px);

    @media all and (max-width: 767px) {
      width: calc(100% - 60px) !important;
    }
  }

  &.iszip {
    .pac-container.pac-logo {
      transform: translateY(68px) translateX(-32px);
    }
  }

  .search-form .absolute.pac-container,
  .search-form-row .absolute.pac-container {
    width: 386px !important;

    @media all and (max-width: 767px) {
      width: 100% !important;
    }
  }
}

.search-form-row .pac-container.pac-logo,
.search-form .pac-container.pac-logo {
  padding-top: 0;
  width: 100% !important;
  border-radius: 6px;
  top: 112px !important;
  visibility: visible;
  left: 0 !important;
  height: 260px !important;
  z-index: 1001;
}

.search-form-row .pac-container.pac-logo {
  top: 103px !important;
}

.pac-container.pac-logo .pac-item {
  padding: 0.5rem;
}

span.pac-item-query,
span.pac-item-query+span {
  font-family: Montserrat, sans-serif;
}

span.pac-icon.pac-icon-marker {
  margin-left: 8px;
}

.min-width-cusines {
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
}

/* Responsive css*/

@media all and (max-width: 991px) {
  .min-width-cusines.mx-16 {
    margin: 15px 0 0;
  }

  .form-wrap .search-form {
    min-width: 0 !important;
    width: 100%;
    max-width: 100% !important;
    margin: 0;
  }

  .filter-bar .grid {
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .filter-bar .grid>div,
  .filter-bar .grid>div.search-field {
    width: 33.333%;
  }

  .filter-bar .grid>div:nth-child(3) {
    padding-right: 0;
  }

  .filter-bar .grid .dd-button-cta--primary-alt {
    margin: 15px 0 0;
  }
}

@media all and (max-width: 767px) {
  .header .dd-nav__tab {
    padding-right: 15px;
  }

  .about-us {
    flex-direction: column-reverse;
    padding: 0;
    margin: 0;
  }

  .about-us .vedio-wrap,
  .about-us .data-wrap {
    width: 100%;
    padding-left: 0;

    .heading-with-bar {
      display: none;
    }
  }

  .about-us .vedio-wrap {
    margin-top: 80px;
    order: 2;
    padding-right: 0;
    margin-bottom: 33px;

    @media all and (max-width: 767px) {
      margin-top: 24px;
    }
  }

  .location-banner {
    flex-direction: column;
    padding: 15px;
  }

  .location-banner .banner-data {
    width: 100%;
    padding: 0 0 20px;
  }

  .location-banner .cheif-banner-image {
    position: relative;
    right: 0;
    top: 0;
  }

  .filter-bar .grid {
    flex-direction: column;
    align-items: flex-start;
  }

  .filter-bar .grid>div,
  .filter-bar .grid>div.search-field {
    width: 100%;
    padding: 0;
    margin-bottom: 15px;
  }

  .filter-bar .grid .dd-button-cta--primary-alt {
    margin: 0;
    width: 100%;
  }
}

.food-safety-covid-19 {
  background: url(/assets/images/backgrounds/covid-19.png);
}

@media all and (max-width: 991px) {

  #my-orders .container>.col-span-5,
  #my-orders .container>.col-span-2 {
    grid-column: span 12 / span 12;
    padding-right: 0;
  }

  .checkout-summary {
    margin-top: 30px;
  }

  .bg-password {
    padding: 20px;
  }
}

@media all and (max-width: 991px) and (min-width: 768px) {
  .form-input {
    margin-left: -7px;
    margin-right: -7px;
  }

  .form-input .dd-form-field {
    padding-left: 7px;
    padding-right: 7px;
  }
}

@media all and (max-width: 767px) {
  .cart-wrap .user-desc h3 {
    margin-bottom: 5px;
  }

  .cart-wrap li .left-col {
    padding-bottom: 10px;
  }

  .cart-wrap .img-frame {
    width: 60px;
    height: 60px;
  }

  .chef-of-month .items-center.relative {
    flex-direction: column;
  }

  .chef-of-month .items-center.relative .order-first+div {
    width: 100%;
    padding: 20px 0 0;
  }

  .cheff-detail .box-with-shadow {
    flex-direction: column;
    padding: 15px;
  }

  .cheff-detail .box-with-shadow .img-wrap {
    width: 100%;
    height: 100%;
    padding-top: 100%;
  }

  .cheff-detail .box-with-shadow .data-wrap {
    width: 100%;
    padding: 20px 0 0;
  }

  .cheff-detail .box-with-shadow .bg-tan+.bg-tan {
    margin-left: 15px;
  }

  .cheff-detail .box-with-shadow .bg-tan span {
    white-space: nowrap;
  }
}

.manage-emp .tabs-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .tabs {
    min-width: 118px;
    padding: 15px 32px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 700;
    color: #222;
    border: 1px solid transparent;

    @media all and (max-width: 1099px) {
      min-width: 0;
      padding: 15px;
    }

    @media all and (max-width: 639px) {
      padding: 8px;
    }

    &+.tabs {
      margin-left: 6px;
    }

    &:hover,
    &.active {
      color: #de3530;
      border-color: #de3530;
    }
  }
}

.search-form .result {
  font-size: 14px;
  line-height: 20px;
  color: #777;
  @apply font-secondary;
}

.table-row {
  width: 100%;
  display: block;
  position: relative;

  .inner {
    display: flex;
    font-size: 14px;
    color: #222;
    padding: 10px 0;

    .column {
      padding: 10px;
      flex: 1;
      @apply font-secondary;
      &.color-777{
        color: #777777;
      }

      &:nth-child(1) {
        padding-left: 0;

        @media all and (max-width: 1023px) {
          padding-left: 30px;
        }
      }

      &:last-child {
        padding-right: 0;
      }

      &:nth-child(2) {
        text-align: center;
      }

      &:nth-child(3),
      &:nth-child(4) {
        text-align: center;
        max-width: 200px;
      }

      &:nth-child(5),
      &:nth-child(6) {
        max-width: 150px;
        text-align: center;
      }

      &:nth-child(6) {
        text-align: right;
      }

      .dot {
        background: #c0c0c0;
        font-size: 0;
        width: 11px;
        height: 11px;
        display: inline-block;
        border-radius: 11px;
        vertical-align: top;

        &.active {
          background: #16b51f;
        }
      }

      .submenu {
        position: relative;
        width: 40px;
        height: 40px;
        right: 0;
        top: auto;
        display: inline-block;
        margin-bottom: -20px;
        vertical-align: top;

        .title-change {
          position: relative;
          left: auto;
          top: 0;
          transform: rotate(90deg);
          right: 0;
        }

        .dropdown-nv {
          right: 0;
          top: 17px;
          padding: 25px;
          min-width: 200px;

          .nv-items {
            margin-bottom: 20px;

            &.deactivate {
              &>svg {
                display: none;
              }

              &:before {
                width: 14px;
                height: 14px;
                background: #c0c0c0;
                display: inline-block;
                content: "";
                border-radius: 100%;
                margin-right: 12px;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      @media all and (max-width: 1023px) {

        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          display: none;
        }
      }

      @media all and (max-width: 767px) {
        &:nth-child(3) {
          display: none;
        }
      }

      @media all and (max-width: 479px) {
        &:nth-child(2) {
          display: none;
        }
      }

      .check-btn {
        position: relative;
        top: 0;
        left: 0;
        display: inline-block;
        border: solid 1px #ccc;
        vertical-align: top;
        margin-right: 20px;

        &:checked {
          border-color: transparent;
        }
      }
    }
  }

  .check-btn {
    position: absolute;
    top: 20px;
    left: 0;
    display: none;
    background-size: 10px;
    appearance: none;
    -webkit-appearance: none;
    width: 18px;
    height: 18px;
    cursor: pointer;
    border-radius: 3px;
    border: solid 1px #de3530;
    &.check-btn-top{
      top: 23px;
    }

    @media all and (max-width: 1023px) {
      display: block;
    }

    &:checked {
      &+.inner {
        @media all and (max-width: 1023px) {
          flex-wrap: wrap;
          padding-bottom: 0;
        }

        .column {
          @media all and (max-width: 1023px) {
            border-bottom: 1px solid rgba(34, 34, 34, 0.1);
          }

          &:last-child {
            margin-bottom: -1px;
          }

          &:before {
            content: attr(data-title);
            display: none;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 600;
          }

          @media all and (max-width: 1023px) {

            &:nth-child(7),
            &:nth-child(8),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6) {
              display: flex;
              width: 100%;
              flex: initial;
              max-width: 100%;
              justify-content: space-between;
              padding: 15px 0;
              align-items: center;

              &:before {
                display: block;
              }
            }
          }

          @media all and (max-width: 767px) {
            &:nth-child(3) {
              display: flex;
              width: 100%;
              flex: initial;
              max-width: 100%;
              justify-content: space-between;
              align-items: center;
              padding: 15px 0;

              &:before {
                display: block;
              }
            }
          }

          @media all and (max-width: 479px) {
            &:nth-child(2) {
              display: flex;
              width: 100%;
              flex: initial;
              max-width: 100%;
              justify-content: space-between;
              align-items: center;
              padding: 15px 0;

              &:before {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  &.heading {
    .inner {
      color: #777;
      padding: 0 0 10px;
      &.inner-p-0{
        padding: 0;
      }

      .column {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }

  &.data {
    border-bottom: 1px solid rgba(34, 34, 34, 0.1);

    &:last-child {
      border: 0;
    }
  }
}

.load-more {
  padding: 54px 0 114px;

  button {
    font-size: 16px;
    color: #222;
    font-weight: 700;
  }
}

.table-wrap {
  @media all and (max-width: 991px) {
    padding: 25px 15px;
  }
  @media (max-width: 767px) {
    padding: 25px 15px 25px 10px;
  }

  &>.manage-emp {
    margin: -25px -25px 10px;
    padding-top: 20px;

    .tabs {
      font-size: 14px;
      font-weight: 500;
      padding: 12px 20px;
      @apply font-secondary;
      min-width: 0;
      text-align: center;
      border: 0;
      color: #777;

      strong {
        font-weight: 700;
        color: #de3530;
        @apply font-secondary;
        margin-left: 2px;
      }

      &+.tabs {
        margin-left: 0;

        @media all and (min-width: 1200px) {
          margin-left: 24px;
        }
      }

      &:hover,
      &.active {
        color: #777;
      }
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 28px;
  margin: 0 0 0 10px;
  padding: 2px 22px 2px 2px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  width: 56px;
  height: 28px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ef9a98;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.05);
}

.slider:before {
  position: absolute;
  content: "";
  left: 1px;
  bottom: 1px;
  background-color: white;
  width: 32px;
  height: 26px;
  padding: 2px 22px 2px 2px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.05);
}

input:checked+.slider {
  background-color: #30d126;
}

input:focus+.slider {
  box-shadow: 0 0 1px #30d126;
}

input:checked+.slider:before {
  -webkit-transform: translateX(22px);
  -ms-transform: translateX(22px);
  transform: translateX(22px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 100px;
}

.slider.round:before {
  border-radius: 100px;
}

.mod-body .btn-wrap {
  padding-top: 10px;
}

.btn-wrap .title {
  font-size: 14px;
  color: #222;
  line-height: 1;
}

.upload-file-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 4px 4px 20px;
  border-radius: 12px;
  border: solid 1px #e8e8e8;
  background-color: #fff;
  margin-bottom: 15px;

  .file-name {
    font-size: 14px;
    color: #777;
  }

  .otp-btn {
    position: relative;
    margin: 0;

    input[type="file"] {
      position: absolute;
      width: 250px;
      left: -100px;
      top: 0;
      padding: 0;
      opacity: 0;
      height: 40px;
      cursor: pointer;
    }
  }
}

.download-btn {
  font-size: 16px;
  font-weight: 700;
  color: #de3530;
  display: inline-block;
  line-height: 1;
  vertical-align: top;
}

.preview-page {
  background: #fafafa;
}

.page-header {
  padding: 26px;
  //  box-shadow: 1px 4px 4px 0 rgb(0 0 0 / 2%);
  background-color: #fff;

  h2 {
    font-size: 32px;
    line-height: 40px;
    padding: 0 20px;

    @media all and (max-width: 767px) {
      font-size: 25px;
      line-height: 30px;
    }
  }

  .inner {
    position: relative;
  }

  .close-btn {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 0;
    width: 25px;
    height: 25px;
    bottom: 0;
    vertical-align: top;
    margin: auto;
    line-height: 1;
  }
}

.page-content {
  margin-top: 50px;

  .action-wrap {
    padding: 126px 0 26px;

    .update-btn {
      font-size: 16px;
      padding: 12px 26px 12px;
      border-radius: 10px;
    }
  }
}

.preview-page .table-section {
  @media all and (max-width: 991px) {
    padding: 25px 15px;
  }
}

.dd-modal--cc .label-text {
  text-align: left;
}

.leading-10 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;

  .bg-black {
    margin-right: 15px;
    margin-top: 15px;
  }
}

.banner-data .leading-10 br {
  @media all and (max-width: 991px) {
    display: none;
  }
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.notification .btn-wrap {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
}

.notification h3.font-semibold {
  font-size: 1.15rem;
  padding: 0 15px;
}

.notification .btn-wrap .dd-button-cta--primary-alt.cancel:hover,
.notification .btn-wrap .dd-button-cta--primary-alt.cancel {
  background: #7a7575;
}

@media all and (min-width: 992px) {
  .header #navMenu ul:not(.nologin) li.dd-nav__tab:last-child {
    padding-right: 20px;

    &.last {
      display: none;
    }
  }
}

@media all and (min-width: 1200px) {
  .header #navMenu ul:not(.nologin) li.dd-nav__tab:last-child {
    padding-right: 40px;
  }
}

@media all and (max-width: 991px) {
  .cuisine .carousel li {
    padding-left: 15px;
    padding-right: 15px;
  }

  .details-container .cc-section {
    padding: 20px 30px 20px 20px;
  }
}

@media all and (max-width: 767px) {
  #dd-application-chef-details .grid-cols-3.gap-4 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media all and (max-width: 479px) {
  #dd-application-chef-details .grid-cols-3.gap-4 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.cc-with-radio .details-container {
  width: 100%;
  min-height: 0;
}

.cc-with-radio .submenu {
  right: -10px;
  top: 2px;
  text-align: center;
  padding-top: 0;
  width: 20px;
  height: 20px;
}

.cc-with-radio .radio-container {
  margin-top: 6px;
}

.payment-box .cc-with-radio .radio-container {
  margin-top: 3px;
}

.payment-box .cc-with-radio .details-container .title {
  padding-bottom: 0;
}

.cc-with-radio .submenu button.title-change {
  position: relative;
  top: 0;
  right: 0;
}

.cc-section .submenu {
  width: 20px;
  height: 37px;
  top: 20px;
  bottom: 0;
  right: -9px;
  margin: auto;
  text-align: right;
}

.tabs-section .cc-section .submenu {
  right: 13px;
}

.cc-section .submenu button.title-change {
  position: relative;
  top: 0;
  left: -8px;
  right: 0;
}

#my_profile .cc-section .submenu {
  right: 12px;
}

.price-qty span.ratings {
  display: inline-flex;
  align-items: center;
}

.price-qty span.ratings>img {
  width: 19px;
  margin: 0 6px;
}

.choose-account .account-type {
  border: 1px solid #c9c9c9;
  border-radius: 20px;
  min-height: 128px;
  width: 100%;
  text-align: left;
  font-size: 20px;
  font-weight: 700;
  color: #de3530;
  padding: 30px;
  position: relative;
  display: flex;
  align-items: center;
  @apply font-secondary;

  &::after {
    content: "";
    width: 24px;
    height: 24px;
    display: inline-block;
    position: absolute;
    right: 30px;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 2px;
    background: url(../images/icons/right-aero-dark.svg);
  }

  i {
    margin-right: 30px;
    display: block;

    @media all and (max-width: 479px) {
      margin-right: 20px;
    }
  }

  @media all and (max-width: 479px) {
    padding: 30px;
  }
}

.choose-account .account-type:after {
  @media all and (max-width: 479px) {
    right: 20px;
  }
}

.choose-account .account-type+.account-type {
  margin-top: 20px;
}

.member-row {
  justify-content: space-between;

  &>div {
    width: calc(50% - 15px);

    .box-with-shadow {
      display: block;
      padding: 20px;

      .commision-info {
        margin: 0;
      }
    }
  }

  @media all and (max-width: 991px) {
    flex-direction: column;

    &>div {
      width: 100%;

      &+div {
        margin-top: 30px;
      }
    }
  }
}

.content-wrap * {
  @apply font-secondary;
}

.content-wrap.chef * {
  @apply font-standard;
}

.content-wrap.chef .chef-login-container form * {
  @apply font-secondary;
}

.content-wrap .title {
  font-size: 14px;
  color: #222;
  line-height: 18px;
  display: block;
  margin-bottom: 5px;
  font-weight: 700;
}

.code-wrap {
  display: flex;
  margin-bottom: 22px;
  align-items: center;

  &.with-border {
    margin-bottom: 13px;
    border-bottom: 1px solid #e9e9e9;
    padding-bottom: 16px;
  }

  .copy-icon {
    min-width: 24px;
  }
}

.code-wrap .code {
  color: #777;
  font-size: 15px;
  margin-right: 10px;
  line-height: 19px;
  font-weight: 400;

  &.bigger {
    line-height: 22px;
    font-size: 18px;
    letter-spacing: 2.25px;
    font-weight: 700;
  }
}

.code-wrap p.desc {
  font-size: 15px;
  line-height: 19px;
  color: #777;

  strong {
    color: #222;
    font-weight: 700;
  }
}

.commision-info {
  background: #f2f2f2;
  padding: 20px;
  border-radius: 22px;
  width: 100%;
  overflow: hidden;
  position: relative;

  .desc {
    margin-bottom: 12px;
    &:last-child{
      margin-bottom: 0;
    }
  }

  &>* {
    position: relative;
    z-index: 2;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% + 2px);
    content: "";
    z-index: 1;
    margin: -1px;
    border-radius: 22px;
    border: 2px dashed #c8c8c8;
    width: calc(100% + 2px);
  }
}

.head-row {
  margin-bottom: 30px;

  @media all and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    color: #222;
    line-height: normal;
  }

  .text-btn {
    font-size: 16px;

    @media all and (max-width: 767px) {
      margin-top: 15px;
    }
  }
}

.dashboad-wrap {
  margin-left: 0px;
  margin-right: 0px;
}

.data-row {
  flex-wrap: wrap;
  gap: 20px;

  .column {
    padding: 29px 10px 28px 8px;
    border-radius: 12px;
    background-color: #fafafa;
    width: calc(33.333% - 20px);
    flex-direction: column;
    color: #777;
    font-size: 14px;
    line-height: 28px;

    @media all and (max-width: 767px) {
      width: 100%;

      &+.column {
        margin-top: 15px;
      }
    }

    * {
      @apply font-secondary;
    }

    strong {
      color: #222;
      font-size: 18px;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 4px;
    }

    span {
      text-align: center;
      line-height: normal;
      font-size: 12px;
      font-weight: 500;
      color: #222;
    }
  }
}

.heading-wrap {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &.more-space {
    margin-bottom: 20px;
  }

  .page-head {
    font-size: 20px;
    font-weight: 700;
    color: #222;
  }
}

.modal-dd-center.switch-user {
  padding: 45px 30px;
  text-align: center;
  width: 101%;

  // @media all and (max-width: 479px) {
  //   max-width: 90%;
  // }
  h3 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  p {
    font-size: 15px;
    line-height: 27px;
    margin-bottom: 10px;
  }

  .dd-button-cta {
    padding: 10px 20px 11px 20px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 700;
    height: 50px;
    width: calc(50% - 16px);

    &.text-btn {
      border: solid 1px #de3530;
      color: #de3530;
    }
  }
}

.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  z-index: 99;
}

/* Style the icon bar links */
.icon-bar a {
  display: block;
  text-align: center;
  padding: 12px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar a.facebook {
  background: #3b5998;
  color: white;
  border-top-left-radius: 15px;
}

.icon-bar a.twitter {
  background: #1da1f2;
  color: white;
  padding: 0;
  width: 47px;
  height: 47px;
  img{
    width: 100%;
  }
}

.icon-bar a.google {
  background: #ff8421;
  color: white;
  border-bottom-left-radius: 15px;
}

.icon-bar a.linkedin {
  background: #0e76a8;
  color: white;
}

.custom-select {
  position: relative;
  max-width: 155px;

  @media all and (max-width: 991px) {
    margin-left: 16px;
  }

  @media all and (max-width: 639px) {
    margin-left: 0;
  }
}

.custom-select select {
  min-width: 155px;
  height: 48px;
  padding: 12px 8px 12px 20px;
  border-radius: 12px;
  border: solid 1px #e8e8e8;
  background: #fff;
  background-image: none !important;
  font-size: 14px;
  color: #222;
  font-weight: 600;
  @apply font-secondary;
}
.custom-select.no-border select {
  border: unset;
}

.custom-select .drop-aero {
  position: absolute;
  right: 7px;
  top: 12px;
  pointer-events: none;
}

@media all and (min-width: 768px) and (max-width: 991px) {
  .account-wrap.with-discount .account-info {
    width: calc(100% - 103px);
  }

  .account-wrap.with-discount .min-width-cusines {
    padding-left: 10px;
  }
}

p.sub-text-for-head {
  margin-top: 0px;
  margin-bottom: 10px;
  color: #777;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  line-height: 1.07;
  font-weight: 500;
}

@media all and (max-width: 1899px) {
  .banner-wrap .heading {
    font-size: 30px;
    line-height: 37px;
  }
}

@media all and (max-width: 1280px) {
  .banner-wrap .heading {
    font-size: 25px;
    line-height: 30px;
  }
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.meal-near-you .view-all {
  font-size: 16px;
}

.meal-near-you .view-all:hover {
  text-decoration: underline;
}

.share-icon a {
  padding: 6px 6px;
  border-radius: 50%;
}

.share-icon a.ic_facebook {
  background: #3b5998;
}

.share-icon a.ic_twitter {
  // background: #1da1f2;
  padding: 0;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  img{
    border-radius: 50%;
  }
}

.share-icon a.ic_linkedin {
  background: #0e76a8;
}

.share-icon a.ic_email {
  background: #ff8421;
}

.user-drop .truncate {
  display: flex;
  align-items: flex-start;
}

.user-drop .truncate>span {
  margin-top: 8px;
  max-width: 90px;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media all and (max-width: 767px) {

    &.drop-aero,
    &.font-semibold {
      // display: none;
    }
  }

  &+span {
    margin-top: 9px;
  }
}

.check-btn:checked {
  background: url(../images/icons/ic-checkmark-red.svg) center no-repeat;
}

.check-btn.with-toggle {
  background: url(../images/icons/plus-red.svg) center no-repeat;
  // background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMBAMAAACkW0HUAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAA9QTFRFAAAA3jUw3jUw3jUw3jUwwOde0QAAAAV0Uk5TAMC/7/Aef0ylAAAAIklEQVR4nGNgYGAUYAAB3JSQkrKRkhKDoKCIo6AgXpUQCgBXGQIR7G3pbwAAAABJRU5ErkJggg==") center no-repeat;
}

.check-btn.with-toggle:checked {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAACAQMAAABbr9+TAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAANQTFRF3jUwpIXX+gAAAAtJREFUeJxjYAABAAAGAAH+jGfIAAAAAElFTkSuQmCC") center no-repeat;
}

.line-chef {
  display: block;
  width: 2px;
  background-color: #2222226e;
  margin-right: 6px;
  height: 21px;
  margin-top: 0;
}

.text-center .loader {
  margin-left: auto;
  margin-right: auto;
}

.specialRequestWord {
  width: 109px;
  height: 18px;
  margin: 0 914px 3px 0;
  font-family: Quicksand;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #222;
  display: block;
}

.cheff-dashboard .content-wrap.chef .specialRequestDiv .specialRequestMessage {
  width: 100%;
  min-height: 44px;
  margin: 3px 0 0;
  @apply font-secondary;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #222;
}

.cheff-dashboard .content-wrap.chef .specialRequestDiv {
  width: 100%;
  min-height: 95px;
  margin: 15px 0 20px;
  padding: 15px 32px 15px 15px;
  border-radius: 22px;
  background-color: #fafafa;
}

#featured-chefs .inner-wrap .img-frame {
  background: #f9f9f9;
}

.load-more-wrap {
  display: flex;
  justify-content: center;
  padding: 24px 0;

  button {
    min-width: 138px;
    height: 48px;
    padding: 14px 20px 14px 20px;
    border-radius: 10px;
    background-color: #fff;
    font-weight: 700;
    @apply font-standard;
  }
}

.qty-div {
  background: #f6f6f6;
  alignitems: center;
  color: black;
  justifycontent: space-evenly;
  padding: 3px 0px;
  display: flex;
  width: 138px;
}

.qty-btn {
  position: relative;
  left: initial;
  right: initial;
  top: initial;
  bottom: initial;
  margin: initial;
  width: 50px;
  height: 34px;
  display: flex;
  alignitems: center;
  justifycontent: center;
}

.card-img img {
  height: 100%;
  width: 100%;
}

.homepage {
  // .meal-near-you {
  //   .heading-with-bar:before {
  //     max-width: 53px;
  //   }
  // }

  @media all and (min-width: 768px) {
    .meal-near-you {
      margin-top: 0;
      padding-bottom: 40px;
    }

    .cuisine {
      padding-bottom: 70px;
    }
  }

  @media all and (max-width: 767px) {
    .mb-8 {
      margin-bottom: 1.5rem;
    }

    .pb-8 {
      padding-bottom: 2.5rem;
    }

    .meal-near-you {
      padding-bottom: 1.5rem;
    }
  }
}

.voucher-meal {
  height: 28px;
  font-size: 12px;
  font-weight: 700;
  width: 120px;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 20px;
  @apply font-secondary;
  color: #de3530;
  background: rgba(#de3530, 0.1);
  text-align: center;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qty-form .voucher-meal {
  margin: 0 0 0 40px;

  @media all and (max-width: 767px) {
    margin-left: 10px;
  }
}

.upload-csv-btn.box-with-shadow {
  display: flex !important;
}
.upload-csv-shadow{
  @media (max-width: 580px) {
    flex-direction: column;
  }
  .upload-csv{
    @media (max-width: 767px) {
      margin-right: 10px;
    }
    @media (max-width: 580px) {
      margin-bottom: 10px;
    }
  }
}

.column {
  .submenu {
    .dropdown-nv {
      padding: 6px 25px !important;
    }

    .dropdown-nv.rounded-xl {
      .nv-items {
        margin-bottom: 0 !important;
        font-weight: 500;
        height: 48px;
        border-bottom: 1px solid #2222221a;
        width: calc(100% + 50px);
        margin-left: -25px;
        margin-right: -25px;
        padding: 6px 20px;
        line-height: normal;

        &:last-child {
          border-bottom-width: 0;
        }
      }
    }
  }
}



// Distribute wallet amount & vouchers Start
.DistributeWalletAmount--Modal {
  max-width: 640px;

  .mod-header {
    padding-bottom: 0;
    border-bottom: 0px solid #2222221a;
    margin-bottom: 20px;
  }

  thead th {
    font-weight: normal;
    font-size: 12px;
    color: #777;
    font-family: Montserrat, sans-serif;

    .check-container {
      margin: -9px 0 0;

    }

    &:first-child {
      padding-left: 0;
      width: 36px;
    }
  }

  tbody td {
    height: 56px;
    font-size: 12px;
    color: #222;
    font-weight: 500;
    font-family: Montserrat, sans-serif;
    border-bottom: 1px solid #2222221a;

    &:first-child {
      padding-left: 0;

      +td {
        font-weight: 600;
      }
    }
  }

  .search-form {
    margin-bottom: 24px;
  }

  .btn-wrapper .dd-button-cta {
    border-width: 0;
    height: 48px;
  }

  .dd-button-cta.dd-button-cta--primary-alt {
    margin-left: 12px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .flex.justify-end.btn-wrapper.mt-4 {
    margin-top: 24px;
  }

  .check-container {
    margin: 0;
    padding: 0;
  }
}

.DistributeBtn {
  width: auto !important;
  justify-content: center;
  @media (max-width: 767px) {
    width: 100% !important;
  }
}

// Distribute wallet amount & vouchers End

.checkout-summary-margin{
  margin-top: 25px;
  @media (max-width: 580px) {
    margin-top: 0px;
  }
  &.margin-top{
    @media (max-width: 580px) {
      margin-top: 10px;
    }
  }
}
.redcheck-center{
  img{
    margin: auto;
    width: 70px;
  }
}
.padding-lr-20{
  padding: 12px 20px;
}
.check-container {
  &.top-check-12{
    top: -10px;
  }
}
.react-dropdown-select-dropdown{
  padding: 15px 10px 15px 25px !important;
  border-radius: 20px !important;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08) !important;
  background-color: #fff !important;
  border: 0 !important;
  .react-dropdown-select-item{
    font-size: 14px !important;
    border: 0 !important;
  }
}
.react-tooltip__show{
  opacity: 1 !important;
}
.save-card-payment {
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
}
.save-card-payment-add {
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
}
.error-info-sec{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  background-color: #fafafa;
  .error-logo-img{
    margin-bottom: 25px;
  }
  .error-heading{
    font-family: 'Montserrat', sans-serif !important;
    font-size: 32px;
    font-weight: bold;
    color: #777;
    margin-bottom: 5px;
  }
  .error-subheading{
    font-family: 'Montserrat', sans-serif !important;
    font-size: 16px;
    font-weight: 500;
    color: #777;
    margin-bottom: 40px;
  }
  .error-btn{
    padding-left: 40px;
    padding-right: 40px;
  }
}
.margin-left-banner-btn{
  margin-left: 0 !important;
}
.margin-bot-35{
  margin-bottom: 35px;
}
.warning-msg-signup{
  font-size: 13px !important;
}
.width-100-margin{
  @media (max-width: 991px) {
    margin: 0 30px !important;
  }
}
.landing-modal-page{
  @media (max-width: 991px){
    width: 100% !important;
    max-width: 100% !important;
  }
}
.delivery-wrap .data-box .message{
  @media (max-width: 991px){
    font-size: 28px;
  }
}
.checkout-summary-margin-32{
  margin-top: 32px;
  margin-bottom: 40px;
}
.create-account-sec-margin{
  margin: 30px 0;
}
.padding-bot-20{
  padding: 20px !important;
}
.padding-all-10{
  @media (max-width: 767px){
    padding: 10px !important;
  }
}
.video-margin-left-d{
  @media (max-width: 475px){
    margin-left: 0 !important;
  }
}
.miniloader {
  height: 0;
  width: 0;
  padding: 10px;
  border: 4px solid #ccc;
  border-right-color: #888;
  border-radius: 22px;
  -webkit-animation: rotate 1s infinite linear;
  position: absolute;
  left: calc(50% - 14px);
  top: 4px;
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
