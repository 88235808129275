.float {
  position: fixed;
  width: 80px;
  height: 80px;
  bottom: 7px;
  right: 60px;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
}

.my-float {
  margin-top: 22px;
  z-index: 2;
}

@media all and (max-width: 767px) {
  .float {
    right: 10px;
    width: 60px;
    bottom: 15px;
    height: 60px;
  }
}

.parentwrap.with-space {
  padding-top: 35px;

  @media all and (max-width: 767px) {
    padding-top: 57px;
  }
}

.bounce2 {
  animation: bounce2 2s ease infinite;
}

@keyframes bounce2 {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(-25%);
  }

  100% {
    transform: translateY(0);
  }
}

.subscribe-XP {
  z-index: 9;
  top: 0;
  right: 0;
  border: 1px solid red;
  color: #fff;
  background: #de3530;
  padding-left: 30px;
  padding: 4px;
  text-align: center;
  position: relative;

  &.XP-note {
    max-width: 776px;
    position: fixed;
    z-index: 10;
    bottom: 20px;
    top: auto;
    border-radius: 18px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: left;
    display: flex;
    padding: 10px 20px 10px 15px;
    align-items: center;
    justify-content: space-between;

    @media all and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      left: 20px;
      right: 20px;
    }

    .message-wrap {
      font-size: 14px;
      padding-right: 20px;
      @apply font-secondary;

      @media all and (max-width: 767px) {
        padding-right: 0;
      }

      strong {
        display: block;
        font-weight: 700;
         @apply font-secondary;
      }
    }
  }

  &.location {
    position: absolute;
    width: 403px;
    left: -14px;
    z-index: 1;
    top: -121px;
    padding: 17px 30px 17px 50px;
    border-radius: 10px;
    font-weight: 700;
    text-align: left;
    background: #de3530 url(../images/icons/map-icon-red.svg) 14px 16px no-repeat;

    .message-wrap {
      font-size: 14px;
      @apply font-secondary;
      font-weight: 600;
    }

    @media all and (max-width: 767px) {
      top: calc(100% + 10px);
      max-width: 316px;
      padding-left: 40px;
      padding-right: 15px;
      background-position: 10px 14px;
    }

    .close-btn {
      background: url(../images/icons/ic-close-white.svg) center no-repeat;
      width: 14px;
      height: 14px;
      position: absolute;
      right: 6px;
      top: 6px;
      font-size: 0;
      cursor: pointer;
      background-size: 10px;
    }

    &::before {
      border-top: 7px solid #de3530;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      content: '';
      position: absolute;
      bottom: -7px;
      left: 30px;

      @media all and (max-width: 767px) {
        border-bottom: 8px solid #de3530;
        border-top: 0;
        bottom: auto;
        top: -8px;
      }
    }

    .message-wrap {
      @media all and (max-width: 767px) {
        max-width: 350px;
        margin: 0 auto;
      }
    }
  }

  .message-wrap {
    font-size: 15px;
  }

  .action-wrap {
    display: flex;
    margin: 0;

    @media all and (max-width: 767px) {
      margin-top: 15px;
      width: 100%;
      justify-content: flex-end;
    }

    button {
      padding: 8px 15px;
      border: 0;
      background: none;
      color: #fff;
      font-weight: 700;
      min-width: 98px;
      display: inline-block;
      text-align: center;
      border-radius: 14px;
      height: 46px;
      border-color: #fff;
      border-width: 1px;
      border: 0px solid #fff;
      font-size: 16px;
      line-height: 22px;

      &.disable {
        padding-right: 0;
        min-width: 0;
        padding-left: 0;
        margin-left: 30px;
        margin-right: 10px;
      }

      &.enable {
        border: 0;
        background: #fff;
        margin-left: 14px;
        color: #de3530;
      }
    }
  }
}
