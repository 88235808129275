.header {
  padding: 25px 0;
}

.header .dd-nav__tab a.dd-button-cta--primary {
  padding: 6px 15px;
  border: 0;
  background: #de3530;
  color: #fff;
  font-weight: 700;
  min-width: 119px;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
  height: 40px;
  line-height: 27px;

  @media all and (max-width: 767px) {
    min-width: 0;
    padding: 4px 7px;
    height: 35px;
  }
}

.header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .cart-btn {
    margin-right: 10px;

    @media all and (min-width: 1200px) {
      margin-right: 30px;
    }
  }

  &.sm-wp-container-nav {
    @media all and (max-width: 991px) {
      display: none;
      background-color: #fff;
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 99;
      align-items: flex-start;
      padding-left: 0;
      padding-top: 67px;

      &.after-login {
        flex-direction: column-reverse;
        justify-content: flex-end;

        .user-drop {
          border-bottom: 1px solid rgba(34, 34, 34, 0.1);
          width: calc(100% - 40px);
          padding-bottom: 30px;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 20px;
          position: static;

          .show {
            &.truncate > span + span {
              transform: rotate(180deg);
            }
          }

          &:hover {
            .drop-down {
              display: none;
            }
          }

          .drop-down {
            top: 0;
            left: 0;
            box-shadow: none;
            padding: 0;
            margin-top: 21px;
            margin-left: 10px;
            position: static;
            left: 0;
            top: 0;
            margin-bottom: 0px;

            .last {
              display: none;
            }

            &.show {
              display: block;
            }
          }
        }
      }

      &.active {
        display: flex;
      }

      .dd-nav__tab {
        margin-right: 0;
        padding: 30px 0 0;
        width: 100%;

        &.user-drop {
          border: none !important;
          padding-bottom: 0;
          margin-left: 0;
        }

        &.last {
          display: flex;
          position: absolute;
          bottom: 0;
          left: 0;
          position: absolute;
          bottom: 0;
          width: calc(100% - 40px);
          border-top: 1px solid rgba(255, 86, 86, 0.1);
          padding: 30px 0;
          left: 20px;
        }

        a.dd-button-cta--primary {
          width: 100%;
          height: 40px;
          line-height: 33px;
        }
      }

      #navMenu {
        padding-left: 20px;

        & + #navMenu {
          padding-left: 0;
          width: 100%;
          position: static;
        }

        & > #navMenu {
          width: 100%;
        }
      }
    }
  }
}

@media all and (max-width: 1124px) {
  .header {
    z-index: 99;
  }
}

.header .site-logo {
  margin-top: -10px;
  margin-bottom: -10px;
}

.footer-logo-wrap > a img,
.header .site-logo img {
  max-width: 71px;
}

.header .dd-nav__tab {
  padding-right: 40px;
}

@media all and (max-width: 1199px) {
  .header .dd-nav__tab {
    padding-right: 20px;
  }
}

.header .dd-nav--lime .dd-nav__tab--selected:after {
  display: none;
}

.header .dd-nav__tab.dd-nav__tab--selected a {
  font-weight: 600;
}

.header .dd-nav__tab a {
  font-size: 16px;
  font-weight: 400;
  color: #222;

  &.active {
    font-weight: 600;
  }
}

@media all and (max-width: 991px) {
  .header .dd-nav__tab a {
    font-size: 16px;
  }
}

.header .dd-nav__tab:last-child {
  padding-right: 0;
}

.header .toggle-btn {
  margin-right: 8px;
  margin-top: 0;

  @media all and (min-width: 992px) {
    display: none;
  }

  @media all and (max-width: 991px) {
    &.close {
      position: absolute;
      right: 20px;
      top: 67px;
      background: url(../../assets/images/icons/ic-close.svg) center no-repeat;

      & > span {
        visibility: hidden;
      }
    }
  }
}

@media all and (min-width: 1125px) {
  .header .toggle-btn {
    display: none;
  }
}

.mobile-responsive {
  display: flex;
  align-items: center;

  @media all and (min-width: 1125px) {
    padding-left: 40px;
  }
}

.mobile-responsive > .dd-nav__tab {
  padding-right: 0;
}

@media all and (min-width: 992px) {
  .mobile {
    display: none;
  }
}

@media all and (max-width: 991px) {
  .desktop {
    display: none;
  }

  .sm-navbar nav.dd-nav--white {
    display: flex;
  }

  .sm-navbar nav .container {
    flex-direction: column;
    align-items: flex-start;
  }

  .sm-navbar nav #navMenu.flex {
    display: flex;
    position: relative;
    background: none;
    top: 0;
    padding: 0;
    margin-top:0px;
  }

  .header .dd-nav__tab:last-child {
    padding-right: 0;
    margin-right: 39px;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 4px;
  }

  .user-drop {
    margin-left: 10px;
    margin-top: 2px;
  }

  .user-drop a > span {
    display: none;
  }

  .sm-navbar nav #navMenu.active {
    display: block;
  }

  .sm-navbar nav .container ul {
    flex-direction: column;
    align-items: flex-start;
  }

  .sm-navbar nav .container #navMenu.active ul {
    background: #fff;
    padding-left: 15px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .sm-navbar nav .container .mobile-responsive ul {
    background: none;
  }

  .sm-navbar nav .container ul.nologin {
    flex-direction: column;
    align-items: center;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: auto;
    // max-width: 260px;
    padding-bottom: 30px;

    .dd-nav__tab {
      text-align: center;
    }
  }

  .header .dd-nav__tab {
    padding-right: 30px;
    margin-bottom: 0;
    padding-top: 11px;
    padding-bottom: 5px;
  }

  .mobile-responsive > .dd-nav__tab {
    padding-right: 0;
    margin-right: 0;
    margin-top: -7px;
  }

  .toggle-btn span {
    display: block;
    width: 25px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #000;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
  }
}

.header .dd-nav__tab.user-drop > span {
  height: 50px;
  display: inline-flex;
  align-items: center;
  margin: -25px 0;

  .drop-aero {
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
      max-width: inherit;
    }
    &.show {
      transform: rotate(180deg);
    }
    @media all and (min-width: 992px) {
      display: none;
    }
  }
}
.header .dd-nav__tab.user-drop .drop-down > * {
  margin-bottom: 9px;
}
.header .dd-nav__tab.user-drop .drop-down > *:last-child {
  margin-bottom: 0;
}

.header .dd-nav__tab.user-drop {
  @media all and (max-width: 991px) {
    left: 0;
    top: 0;
    & > span {
      margin: 0;
      height: auto;
    }
  }
  .drop-down {
    top: 33px;
    right: auto;
    left: -17px;
    padding: 15px;
    min-width: 140px;
    @media all and (max-width: 991px) {
      position: relative !important;
      top: 0 !important;
      margin-left: 0px !important;
      box-shadow: none !important;
      border-radius: 0 !important;
      padding: 0;
      left: 6px;
      margin-top: 15px;
      &.show {
        display: block !important;
      }
    }
  }

  &:hover .drop-down {
    display: none;
    @media all and (min-width: 992px) {
      display: block;
      z-index: 1;
    }
  }
}

.header .dd-nav__tab.user-drop .drop-down > * a:hover {
  color: #de3530;
}
