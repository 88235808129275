.password-show {
  position: absolute;
  right: 13px;
  top: 15px;
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  display: block;
  cursor: pointer;

  &.with-close-eye {
    background-image: url(../images/icons/hint-icon-close.svg);
  }

  &.with-open-eye {
    background-image: url(../images/icons/hint-icon.svg);
  }
}

.changePassword-show {
  position: absolute;
  right: 30px;
  top: 37px;
  background-position: center;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  display: block;
  cursor: pointer;

  &.with-close-eye {
    background-image: url(../images/icons/hint-icon-close.svg);
  }

  &.with-open-eye {
    background-image: url(../images/icons/hint-icon.svg);
  }
}

.reset {
  .changePassword-show {
    right: 13px;
    top: 13px;
  }
}
.chefpassword{
  margin-top: 8px;
}



.error {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  text-align: left;
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
  font-size: 0.75rem;
  line-height: 1rem;
}