.reports_dashboard.tabs-section .tabs {
  width: 280px;
  position: fixed;
  left: 0;
  height: 100%;
  top: 0;
  padding: 0;
}

.reports_dashboard.tabs-section .tabs .inner {
  background: #fff;
  border-radius: 0 22px 22px 0;
  padding: 13px 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media all and (max-width: 767px) {
  .reports_dashboard.tabs-section .tabs {
    left: -100%;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 0;
    width: 100%;

    .inner {
      max-width: 280px;
    }

    &.show {
      left: 0;
      z-index: 999;
    }
  }
}

.reports_dashboard.tabs-section .inner-wraper {
  padding-left: 32px;
  padding-right: 32px;
  display: block;

  @media all and (min-width: 768px) {
    width: calc(100% - 280px);
    margin-left: 280px;
  }

  @media all and (max-width: 767px) {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 0;
  }
}

.tabs-section.reports_dashboard {
  min-height: 100vh;
  padding-bottom: 0;
  flex-direction: column;

  .mobile-header {
    display: none;
  }

  @media all and (max-width: 767px) {
    .mobile-header {
      display: flex;
      padding: 10px 20px 0;

      .site-logo {
        margin: 0;
        padding: 0;
      }
    }
  }
}

.tabs-section.reports_dashboard * {
  @apply font-secondary;
}

.reports_dashboard .site-logo {
  margin: 0px 0 40px 32px;
  display: inline-block;
}

.reports_dashboard.tabs-section .tabs label {
  @apply font-secondary;
  padding: 0;
  font-weight: 600;
  color: #777;
  position: relative;
  display: block;
}

.reports_dashboard .tabs .site-logo + .close-nav {
  cursor: pointer;
  margin-right: 21px;
  margin-top: 11px;

  @media all and (min-width: 768px) {
    display: none;
  }
}

.reports_dashboard.tabs-section .tabs label a {
  padding: 18px 24px;
  display: block;
}

.reports_dashboard.tabs-section .tabs label.active {
  color: #de3530;
  font-weight: 700;
}

.reports_dashboard.tabs-section .tabs label:hover,
input#allorders ~ .tabs label[for='allorders']:hover,
input#corporateuserreport ~ .tabs label[for='corporateuserreport']:hover {
  color: #de3530;
}
.reports_dashboard.tabs-section .tabs label.active:before,
.reports_dashboard.tabs-section .tabs label:hover:before {
  width: 4px;
  height: 100%;
  background: #de3530;
  position: absolute;
  left: 0;
  top: 0;
  content: '';
}

.reports_dashboard .header {
  padding: 20px 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  @media all and (max-width: 767px) {
    padding-top: 0;
  }
}

.reports_dashboard .header > * {
  padding-top: 20px;
}

.reports_dashboard .header .form-with-action {
  width: 502px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.reports_dashboard .header .form-with-action.wide {
  width: 644px;
}

.reports_dashboard .header .section-heading {
  font-size: 32px;
  line-height: 39px;
  margin-bottom: 8px;
}

.reports_dashboard .header .subtext {
  font-size: 14px;
  line-height: 18px;
  color: #777;
}

.reports_dashboard .table-section {
  border-radius: 22px;
  box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, 0.02);
  background-color: #fff;
}

.reports_dashboard .table-row .inner .column:nth-child(3) {
  text-align: left;
  max-width: initial;
}

.reports_dashboard .table-row .inner .column:nth-child(4) {
  max-width: initial;
}

.reports_dashboard .table-row .inner .column:nth-child(5),
.reports_dashboard .table-row .inner .column:nth-child(3) {
  max-width: initial;
}

.reports_dashboard .table-row .inner .column:nth-child(2) {
  min-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
}

.reports_dashboard .table-row {
  @media all and (min-width: 1300px) {
    padding: 0 24px;
  }
}

.reports_dashboard .table-row .check-btn {
  top: 16px;
  left: 13px;
}

.reports_dashboard .table-row .inner {
  font-size: 12px;
}

.reports_dashboard .table-row.headings .inner .column {
  color: #777;
}

@media all and (max-width: 1299px) {
  .reports_dashboard .table-row .inner {
    padding: 0;
    flex-wrap: wrap;
  }

  .reports_dashboard .table-row .inner .column {
    padding: 15px !important;
  }

  .reports_dashboard .table-row .inner .column:nth-child(1) {
    padding-left: 40px !important;
    flex-wrap: wrap;
  }

  .reports_dashboard .table-row .check-btn {
    display: block;
  }

  .reports_dashboard .table-row .inner .column:nth-child(4) ~ .column {
    display: none;
  }

  .reports_dashboard .table-row .check-btn:checked + .inner .column:nth-child(4) ~ .column:before {
    display: block;
  }

  .reports_dashboard .table-row .check-btn:checked + .inner .column:nth-child(4) ~ .column {
    display: flex;
    width: 100%;
    flex: initial;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
  }

  .reports_dashboard .table-row .check-btn:checked + .inner .column {
    border-bottom: 1px solid rgba(34, 34, 34, 0.1);
    padding: 15px 0;

    &:last-child {
      border: 0;
    }
  }
}

.view-detail {
  color: #de3530;
}

@media all and (max-width: 767px) {
  .reports_dashboard .table-row .inner {
    padding: 0;
  }

  .reports_dashboard .table-row .check-btn {
    left: 12px;
  }
}

.loader-wraper {
  text-align: center;

  .loader {
    display: inline-block;
    margin-left: 0px;
    margin-right: 0;
  }
}

.reports_dashboard .search-form {
  display: flex;
  align-items: center;
  width: calc(100% - 150px);
  padding-right: 16px;
  flex: 1;

  @media all and (max-width: 991px) {
    flex: initial;
    width: 100%;
    padding: 0 0 15px;
  }
}

.reports_dashboard .search-form .form-wrap {
  flex: 1;
  position: relative;
}

.reports_dashboard .search-form .form-wrap img {
  position: absolute;
  top: 14px;
  left: 19px;
  opacity: 0.55;
}

.reports_dashboard .search-form .form-wrap .search {
  background: #f2f2f2;
  width: 100%;
  height: 48px;
  padding: 15px 15px 15px 50px;
  border-radius: 12px;
  font-family: Montserrat, sans-serif;
  font-size: 15px;

  @media all and (max-width: 767px) {
    font-size: 13px;
  }
}

.reports_dashboard .form-with-action a,
.reports_dashboard .form-with-action button {
  padding: 10px 21px 10px 20px;
  border-radius: 10px;
  background-color: #de3530;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  min-width: 126px;
  color: #fff;
  @apply font-standard;
  text-align: center;
}

.reports_dashboard .form-with-action button.open-filters {
  background: #fff;
  color: #222;
  margin-right: 16px;
}

.load-more-wrap {
  display: flex;
  justify-content: center;
  padding: 24px 0;

  button {
    min-width: 138px;
    height: 48px;
    padding: 14px 20px 14px 20px;
    border-radius: 10px;
    background-color: #fff;
    font-weight: 700;
    @apply font-standard;
  }
}

.report-data-row {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #777;
  padding: 16px 8px;
  position: relative;

  &::after {
    width: calc(100% + 51px);
    left: -26px;
    content: '';
    position: absolute;
    height: 1px;
    background: #f0f0f0;
    bottom: 0;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;

    &:after {
      display: none;
    }
  }

  .data {
    font-weight: 600;
    color: #222;
    max-width: 75%;
  }
}

.filters {
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
  transition: all 100ms ease 0s;

  .inner-wrap {
    width: 280px;
    height: 100%;
    overflow-y: auto;
    padding: 40px 0 121px;
    box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, 0.02);
    background-color: #fff;
    right: -100%;
    transition: all 400ms ease 0s;
    border-radius: 22px 0 0 22px;
  }

  .close-filter {
    cursor: pointer;
  }

  &.show {
    opacity: 1;
    z-index: 999;

    .inner-wrap {
      right: 0;
    }
  }
}

.filters-row {
  border-bottom: 1px solid #f0f0f0;
  padding: 0 16px;
  position: relative;

  .filter-title {
    padding: 19px 0;
    width: 100%;
    display: block;
    font-size: 14px;
    color: #222222;
    font-weight: 600;
    cursor: pointer;
  }

  .filters-box {
    display: none;
    padding: 3px 0 32px;
    font-size: 14px;

    & > div {
      margin-bottom: 12px;

      &.all-org {
        max-height: 220px;
        overflow-y: auto;
        overflow-x: hidden;

        & > div {
          margin-bottom: 12px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  input[type='radio'] {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
  }

  .down-aero {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    pointer-events: none;
  }

  input[type='radio']:checked ~ .down-aero {
    transform: rotate(180deg);
  }

  input[type='radio']:checked ~ .filters-box {
    display: block;
  }
}

.reports_dashboard .filters .header {
  padding: 0 16px 24px;
}

.reports_dashboard .filters .header > * {
  padding: 0;
}

.reports_dashboard .filters .header h3 {
  font-size: 20px;
  font-weight: 700;
  color: #222;
}

.reports_dashboard .filters .check-container {
  margin-bottom: 0;
}

.reports_dashboard .filters .check-container .checkmark {
  border-color: #e0e0e0;
}

.reports_dashboard .filters .check-container .block {
  padding-left: 12px;
}

.reports_dashboard .filters .check-container input:checked ~ .checkmark {
  border-color: transparent;
}

.reports_dashboard .filters-row .search-form {
  width: 100%;
  padding-right: 0;
  margin-bottom: 16px;
}

.reports_dashboard .filters-row .view-more {
  color: #de3530;
  font-weight: 600;
  cursor: pointer;
}

.reports_dashboard .filters-row .view-more.less-margin {
  margin-top: -12px;
  display: inline-block;
  vertical-align: top;
}

.reports_dashboard .user-info {
  position: relative;
  cursor: pointer;
}

.reports_dashboard .user-info .user-data {
  display: flex;
  align-items: center;
  padding: 23px 32px 16px;
}

.user-info .user-data strong {
  padding-left: 16px;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
.drop-aero {
  margin-left: 4px;
}
.reports_dashboard .user-info .submenu {
  width: 100%;
  position: relative;
  display: none;
  height: auto;
}

.reports_dashboard .user-info:hover .submenu {
  display: block;
}

.reports_dashboard .user-info:hover .drop-aero {
  transform: rotate(180deg);
}

.user-info .submenu li {
  padding: 16px 32px;
  color: #de3530;
  font-size: 14px;
  font-weight: 600;
  border-top: 1px solid #f0f0f0;
}

body.modal-open {
  overflow: hidden;
}

.reports_dashboard .table-row.user-row .inner .column {
  max-width: initial;
  text-align: center;
  word-break: break-word;
  font-weight: 400;
}

.reports_dashboard .table-row.user-row .inner .column.left-align {
  text-align: left;
}
.reports_dashboard .form-with-action .cta-btns button {
  font-size: 14px;
  min-width: 120px;
}

.form-with-action .cta-btns {
  text-align: right;
  padding: 56px 16px 0 0;
}
.reports_dashboard .form-with-action .cta-btns button.clear {
  background: no-repeat;
  color: #000;
  padding: 0;
  min-width: 0;
  margin-right: 24px;
}

.no-orders {
  text-align: center;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
}
.red {
  width: 11px;
  height: 11px;
  border-radius: 7px;
  background-color: #16b51f;
  margin-left: 50%;
}

.grey {
  width: 11px;
  height: 11px;
  border-radius: 7px;
  background-color: #c0c0c0;
  margin-left: 50%;
}

.text-green-600::before {
  color: #777;
}

.tsc-user-info .title {
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 3px;
  display: inline-block;
}

.tsc-user-info .data {
  font-size: 14px;
  color: #777;
  @apply font-secondary;
}

.tsc-history.table-row .inner .column:nth-child(3),
.tsc-history.table-row .inner .column:nth-child(4) {
  max-width: initial;
  text-align: left;
}
.tsc-history.table-row .inner .column:nth-child(2) {
  min-width: 0;
}
.tsc-history.table-row .inner {
  align-items: center;
}
// .tsc-history.table-row .inner .column:nth-child(4) {
//   @media all and (min-width: 1024px) {
//     max-width: 69px;
//   }
// }

.tsc-history .text-green-600 {
  color: #54d77e;
}
.tsc-history .tsc-col {
  font-size: 18px;
  font-weight: 700;
  color: #222;
}
.tsc-history .tsc-col img {
  margin-right: 16px;
}

.tabs-section .tabs label:hover,
.tabs-section .tabs label.active {
  background-image: linear-gradient(to right, #fff, #ffedec);
}
