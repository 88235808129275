.FormGroup {
  /* @apply bg-tan; */
  margin: 0 0 20px;
  padding: 0;
  border-style: none;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #fdf8da;
  border-radius: 4px;
}

.FormRow {
  /* @apply border-t border-tan; */
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 15px;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}
