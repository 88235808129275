.main-container {
  display: flex;
}

.chef-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 30px 15px;

  @media all and (max-width: 991px) {
    padding-top: 20px;
    padding-right: 0;
  }

  .bar-menu {
    margin-left: 10px;
    display: none;
    cursor: pointer;
  }

  @media all and (max-width: 991px) {
    justify-content: space-between;

    .bar-menu {
      display: block;
    }
  }

  .mobile-logo {
    margin-left: -12px;
    margin-top: -11px;
    margin-bottom: -10px;
    max-width: 52px;

    @media all and (min-width: 992px) {
      display: none;
    }
  }

  .header-add-button {
    margin-right: 1.5rem;

    @media all and (max-width: 767px) {
      margin-right: 0;
    }

    .dd-button-cta--primary-alt {
      font-size: 16px;
      font-weight: bold;
      padding: 0.438rem 1.821rem;

      @media all and (max-width: 767px) {
        font-size: 12px;
        padding: 5px 10px;
        min-width: 0;
      }
    }
  }
}

.inner-content-block {
  padding-left: 15px;
  padding-right: 15px;
}

.cheff-dashboard .sidebar {
  width: 250px;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  transition: all 500ms ease 0s;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .logout {
    color: #fff;
    padding: 30px 20px 30px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    margin-right: 20px;

    @media all and (min-width: 992px) {
      display: none;
    }
  }

  .user-drop {
    margin-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 15px;

    .truncate {
      color: #fff;

      & > span.drop-aero,
      & > span.font-semibold {
        display: block;
        cursor: pointer;
      }

      & > span.drop-aero {
        &.aero-up {
          transform: rotate(180deg);
        }
      }
    }

    .drop-down {
      position: relative;
      left: 0;
      text-align: 0;
      top: 0;
      background: none;
      box-sizing: non;
      box-shadow: none;
      padding: 20px 0 0;
      display: block;

      div > img {
        margin-right: 10px;
      }

      span,
      a {
        color: #fff;
        @apply font-standard;
      }
    }

    @media all and (min-width: 992px) {
      display: none;
    }
  }

  .bar-menu {
    display: none;
  }

  @media all and (max-width: 991px) {
    left: auto;
    left: -100%;
    width: 95%;
    padding-top: 0;
    z-index: 9;
    border-top-left-radius: 0;

    & > * {
      position: relative;
      z-index: 1;
    }

    &:before {
      background: #b72a27;
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      height: 100%;
      z-index: 0;
    }

    &:after {
      background: #fafafa;
      content: '';
      position: absolute;
      width: calc(100% + 5%);
      left: 0;
      top: 0;
      height: 100%;
      z-index: -1;
    }

    &.show {
      left: 0;
    }

    .bar-menu {
      position: absolute;
      right: 20px;
      top: 20px;
      color: #fff;
      cursor: pointer;
      display: block;
      font-size: 0;
      width: 20px;
      height: 20px;
    }
  }
}

.cheff-dashboard .content-wrap {
  width: calc(100% - 250px);
  padding: 0 30px;
  margin-left: 250px;
  margin-bottom: 30px;

  @media all and (max-width: 991px) {
    width: 100%;
    margin-left: 0;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.cheff-dashboard .content-wrap > .container {
  max-width: 100%;
}

@media all and (max-width: 767px) {
  .cheff-dashboard h2.page-head {
    font-size: 20px;
    line-height: 25px;
  }
}

@media all and (max-width: 1365px) and (min-width: 768px) {
  .cheff-dashboard .meal-info .img-frame {
    width: 45%;
  }

  .cheff-dashboard .menu-detail .meal-info .data-wrap {
    width: 55%;
    margin-left: 25px;
  }

  .cheff-dashboard .meal-info {
    flex-direction: row;
  }
}

@media all and (max-width: 767px) {
  .cheff-dashboard .meal-info .img-frame {
    margin-bottom: 25px;
  }

  .cheff-dashboard .menu-detail .meal-info .data-wrap {
    width: 100%;
    margin-left: 0;
  }
}

.revenue-total {
  padding: 0;
}

.revenue-total .price {
  margin-left: 25px;
}

.revenue-total .price strong {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
  display: block;
}

.revenue-total .price h4 {
  font-size: 60px;
  line-height: 75px;
  color: #de3530;
}

.revenue-total .price .growth {
  color: #16b51f;
}

@media all and (max-width: 1099px) {
  .revenue-total {
    padding: 10px 0 20px;
  }

  .revenue-total .price h4 {
    font-size: 35px;
    line-height: 31px;
  }

  .revenue img {
    max-width: 55px;
  }
}

@media all and (max-width: 767px) {
  .revenue-total {
    padding: 0;
  }
  .revenue-total .price h4 {
    font-size: 32px;
    line-height: 31px;
  }
  .revenue-total .price {
    margin-left: 20px;
  }
  .revenue img {
    max-width: 80px;
  }
}

.content-wrap.chef {
  .upload-images {
    max-width: 120px;
    height: 120px;
    border: solid 1.5px rgba(222, 53, 48, 0.5);
    background-color: rgba(222, 53, 48, 0.05);
    border-radius: 15px;

    @media all and (max-width: 767px) {
      height: auto;
      max-width: 100%;
    }
  }
}

// Change password

.form-wrap .dd-form-field {
  border: 0;
  border-radius: 12px;
  margin: 0 0 20px;
  overflow: hidden;
  padding: 0;
  position: relative;
}
