.sidebar {
    width: 250px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #b72a27;
    min-height: 100vh;
    padding-left: 20px;

    .close-sidebar {
        background: url(../../assets/images/icons/ic-close-white.svg) center no-repeat;
    }

    .main-logo {
       max-width: 70px;
       height: auto;
    margin-bottom: 45px;
    margin-left: 15px;
    margin-top: 15px;
    }
}

.menu-list {
    display: flex;
    flex-direction: column;

    li {
        margin-bottom: 10px;
    }

    .list-item {
        margin-bottom: 15px;
        position: relative;
        &:hover,
        &.active {
            &::after {
                content: "";
                background: #b72a27;
                height: 15px;
                width: 15px;
                border-bottom-right-radius: 10px;
                position: absolute;
                right: 0px;
                top: -15px;
            }

            &::before {
                content: "";
                background: #fafafa;
                height: 15px;
                width: 15px;
                position: absolute;
                right: 0px;
                bottom: -15px;
            }

            .NavLink-item,
            .link-item {
                color: #b72a27;
                background: #fafafa;

                &.dashboard {
                    background: #fafafa url(../../assets/images/icons/icon-sidemenu-dashboard-active.svg) 15px center no-repeat;
                }

                &.menu {
                    background: #fafafa url(../../assets/images/icons/menu-icon-2.svg) 15px center no-repeat;
                }

                &.orders {
                    background: #fafafa url(../../assets/images/icons/order-icon-2.svg) 15px center no-repeat;
                }
                &.cooking {
                    background: #fafafa url(../../assets/images/icons/cooking-schedule-hover.svg) 15px center no-repeat;
                }

                &::after {
                    content: "";
                    background: #fafafa;
                    height: 15px;
                    width: 15px;
                    position: absolute;
                    right: 0px;
                    top: -15px;
                }

                &::before {
                    content: "";
                    background: #b72a27;
                    height: 15px;
                    width: 15px;
                    position: absolute;
                    right: 0px;
                    bottom: -15px;
                    border-top-right-radius: 10px;
                }
            }
        }

        .NavLink-item,
        .link-item {
            font-size: 14px;
            font-weight: 600;
            color: #fff;
            padding: 15px 15px;
            display: flex;
            align-items: center;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            position: relative;
            font-family: Montserrat, sans-serif;

            span {
                font-family: Montserrat, sans-serif;
            }

            img {
                width: 24px;
                height: 24px;
                margin-right: 20px;
            }

            &.dashboard {
                background: url(../../assets/images/icons/icon-sidemenu-dashboard-deactive.svg) 15px center no-repeat;
                padding-left: 69px;
            }

            &.menu {
                background: url(../../assets/images/icons/menu-icon.svg) 15px center no-repeat;
                padding-left: 69px;
            }

            &.orders {
                background: url(../../assets/images/icons/order-icon.svg) 15px center no-repeat;
                padding-left: 69px;
            }
            &.cooking {
                background: url(../../assets/images/icons/cooking-schedule.svg) 15px center no-repeat;
                padding-left: 69px;
            }
        }
    }
}