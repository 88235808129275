.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
  overflow: hidden;
}

@keyframes innerloader {
  0% {
    transform: rotate(0deg)
  }

  50% {
    transform: rotate(180deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

.innerloader div {
  position: absolute;
  animation: innerloader 1s linear infinite;
  width: 5em;
  height: 5em;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  box-shadow: 0 3px 0 0 #de372f;
  box-sizing: content-box;
}

.loader {
  width: 121px;
  height: 121px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.innerloader {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.sing-up-loader {
  border-radius: 50%;
  width: 3em;
  height: 3em;
  overflow: hidden;
  margin: 15px auto !important;
}

.sing-up-loader .innerloader div {
  width: 2.05em;
  height: 2.05em;
  left: 7px;
  top: 7px;
  transform-origin: center;
  box-shadow: 0 2px 0 0 #de372f;
}

.submit-loader,
.submit-loader::after {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  overflow: hidden;
  margin-left: 40% !important;
  margin-top: -3px;
}

.submit-loader .innerloader div {
  width: 30px;
  height: 30px;
  left: 4px;
  top: 4px;
  transform-origin: center;
  box-shadow: 0 3px 0 0 #de372f;
}

.loader,
.sing-up-loader {
  margin: 25px calc(50% - 61px);
}

.full-screen {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 99;
}

.full-screen .loader {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
